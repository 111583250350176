// Doel: JSON-bestand met gegevens voor de pagina's van de website

// Importeer de afbeeldingen

import rephaseImage from './assets/images/projects/images/rephasephone.jpg';  // Voeg deze regel toe als je deze afbeelding hebt
import rephaseLogo from './assets/images/rephase.png';  // Voeg deze regel toe als je dit logo hebt
import windesheimLogo from './assets/images/windesheim.png';  // Voeg deze regel toe als je dit logo hebt
import winchatImage from './assets/images/winchat3.png';  // Voeg deze regel toe als je deze afbeelding hebt
import rompslompLogo from './assets/images/rompslomp.png';  // Voeg deze regel toe als je dit logo hebt
import rompslompImage from './assets/images/projects/rompslomp_project.png';  // Voeg deze regel toe als je deze afbeelding hebt
import wijkagentLogo from './assets/images/wijkagentLogo.png';  // Voeg deze regel toe als je dit logo hebt
import wijkagentImage from './assets/images/projects/wijkagent_app.png';  // Voeg deze regel toe als je deze afbeelding hebt
import recreateLogo from './assets/images/recreateImage.png';  // Voeg deze regel toe als je dit logo hebt
import recreateImage from './assets/images/projects/recreate.png';  // Voeg deze regel toe als je deze afbeelding hebt
import fiwihexLogo from './assets/images/fiwihex.PNG';  // Voeg deze regel toe als je dit logo hebt
import fiwihexImage from './assets/images/projects/appsectorok.png';  // Voeg deze regel toe als je deze afbeelding hebt
import orderPickerImage from './assets/images/projects/java-app.png';  // Voeg deze regel toe als je deze afbeelding hebt
import orderPickerLogo from './assets/images/logo_java.png';  // Voeg deze regel toe als je dit logo hebt
import nerdyGadgetsImage from './assets/images/projects/nedrygadgetsipad2.png';  // Voeg deze regel toe als je deze afbeelding hebt
import nerdyGadgetsLogo from './assets/images/LogoNerdyGadgetsV2.png';  // Voeg deze regel toe als je dit logo hebt

// Importeer de iconen
import { SiArduino, SiCsharp, SiExpo, SiJavascript, SiJquery, SiMysql, SiNumpy, SiTailwindcss } from "react-icons/si";
import { FaBootstrap, FaCss3, FaDisease, FaGithub, FaHtml5, FaJava, FaLaravel, FaPhp, FaReact, FaWpforms } from "react-icons/fa";
import { RiTailwindCssFill } from "react-icons/ri";
import { RiSupabaseLine } from "react-icons/ri";
import { FaPython } from "react-icons/fa";
import { SiScikitlearn } from "react-icons/si";
import { SiPandas } from "react-icons/si";
import { SiPytorch } from "react-icons/si";
import { SiJupyter } from "react-icons/si";
import { MdScatterPlot } from "react-icons/md";


// Definieer de projectProps met de juiste JSON-structuur
const projectProps = {
  "projects": [
    {
      "description": "RePhase is een innovatief project waaraan ik heb gewerkt, gericht op het ontwikkelen van een veelzijdige app die gebruikers ondersteunt bij het stellen van doelen, het volgen en creëren van workouts, en het beheren van voedingsplannen. Het is een alles-in-één lifestyle-app die streeft naar het verbeteren van de gezondheid en fitheid van gebruikers. Tijdens dit project heb ik een React Native-app gebouwd die gebruikers in staat stelt om eenvoudig workouts te volgen en toe te voegen. De app biedt een intuïtieve interface en een scala aan functies die het voor gebruikers gemakkelijk maken om hun fitnessactiviteiten te organiseren en te delen. Door de integratie van voedingsplannen helpt de app gebruikers niet alleen fitter te worden, maar ook om een gebalanceerd en gezond dieet te handhaven. Mijn werk aan RePhase demonstreert mijn uitgebreide vaardigheden in mobiele app-ontwikkeling, waaronder mijn vermogen om complexe functionaliteiten te implementeren en een naadloze gebruikerservaring te creëren. ",
      "year": "2024",
      "title": "RePhase",
      "imageSrc": rephaseImage,
      "logo": rephaseLogo,
      "techs": [
        { "title": "React Native", "Icon": FaReact },
        { "title": "Expo", "Icon": SiExpo },
        { "title": "TailwindCSS", "Icon": RiTailwindCssFill },
        { "title": "Supabase", "Icon": RiSupabaseLine },
        { "title": "GitHub", "Icon": FaGithub }
      ]
    },
    {
      "description": "WinChat is een innovatief project dat is gestart door Windesheim met een helder doel voor ogen: Kunnen we een chatbot ontwikkelen die studenten kan ondersteunen met hun vragen? Het antwoord is een volmondig ja. WinChat is een geavanceerde chatbot die specifiek ontworpen is om studenten te helpen met hun vragen over de HBO-ICT opleiding van Windesheim en programmeeronderwerpen. WinChat biedt studenten uitgebreide hulp met hun vragen over de HBO-ICT opleiding, zoals curriculuminformatie, inschrijvingsprocedures, en studietips. Daarnaast assisteert WinChat studenten bij hun programmeervragen, van basisprincipes tot geavanceerde onderwerpen. WinChat is ontwikkeld in Python met behulp van PyTorch. Het maakt gebruik van het krachtige Mistral 7B model, dat verder wordt gefinetuned met prompt engineering en relevante PDF-documenten die informatie bevatten over de HBO-ICT studie. De gebruikersinterface van WinChat is gebouwd in React, wat zorgt voor een responsieve en gebruiksvriendelijke ervaring. Ik ben bekwaam in het ontwikkelen van chatbots en heb expertise in machine learning. Door mijn kennis van zowel de backend als de frontend , ben ik in staat om robuuste en effectieve chatbot-oplossingen te creëren. WinChat is een bewijs van mijn vaardigheden en mijn vermogen om technologie te gebruiken om praktische oplossingen te bieden voor studenten.",
      "year": "2023/2024",
      "title": "WinChat",
      "imageSrc": winchatImage,
      "logo": windesheimLogo,
      "techs": [
        { "title": "Python", "Icon": FaPython },
        { "title": "Scikit-learn", "Icon": SiScikitlearn },
        { "title": "Pandas", "Icon": SiPandas },
        { "title": "PyTorch", "Icon": SiPytorch },
        { "title": "Jupyter", "Icon": SiJupyter },
        { "title": "React", "Icon": FaReact },
        { "title": "Tailwind CSS", "Icon": SiTailwindcss },
        { "title": "GitHub", "Icon": FaGithub },
      ]
    },
    {
      "description": "Tijdens het semester Data Science heb ik een project uitgevoerd voor Rompslomp, een applicatie die zelfstandigen (ZZP'ers) helpt met hun boekhouding. De opdracht was om een diepgaande analyse te doen van de data van Rompslomp, met als doel te ontdekken wat een ondernemer succesvol maakt. Hierbij onderzocht ik verschillende factoren, zoals de invloed van loyale klanten op het succes van een ondernemer. Om deze analyse uit te voeren, heb ik gebruikgemaakt van diverse geavanceerde data-analysetechnieken. Zo heb ik meerdere regressiemodellen, lineaire regressie en beslisbomen toegepast om correlaties en patronen in de financiële gegevens van de gebruikers te identificeren. Deze aanpak toont verschillende professionele kwaliteiten: Probleemoplossend vermogen: Ik heb laten zien dat ik in staat ben om complexe vraagstukken te analyseren en op te lossen door gebruik te maken van geavanceerde technieken. Technische expertise: Het toepassen van meerdere regressiemodellen en beslisbomen toont mijn kennis en vaardigheid in data-analyse en statistische methoden. Inzicht in bedrijfsprocessen: Door te onderzoeken welke factoren bijdragen aan het succes van ondernemers, toon ik aan dat ik begrijp hoe data-analyse kan bijdragen aan strategische besluitvorming binnen een bedrijf. Praktische toepassing: Het werken met echte data van Rompslomp laat zien dat ik mijn theoretische kennis kan toepassen in een praktische, bedrijfsgerichte context.",
      "links": [
        {
          "title": "LinkedIn Post over ons Successvolle Project met Rompslomp",
          "url": "https://www.linkedin.com/pulse/op-weg-naar-succes-samenwerking-met-data-science-studenten-van?utm_source=share&utm_medium=member_android&utm_campaign=share_via",
        }
      ],
      "year": "2023",
      "title": "Rompslomp Data Science Project",
      "imageSrc": rompslompImage,
      "logo": rompslompLogo,
      "techs": [
        { "title": "Python", "Icon": FaPython },
        { "title": "Pandas", "Icon": SiPandas },
        { "title": "Numpy", "Icon": SiNumpy },
        { "title": "Scikit-learn", "Icon": SiScikitlearn },
        { "title": "Matplotlib", "Icon": MdScatterPlot },
        { "title": "Seaborn", "Icon": FaDisease },
        { "title": "Jupyter", "Icon": SiJupyter },
      ]
    },
    {
      "description": "Tijdens het semester OOSDD van mijn HBO-ICT-opleiding heb ik de applicatie 'De Wijkagent' ontwikkeld. Deze stelt politieagenten in staat om incidenten en plaats delicten per wijk in Zwolle te registreren op een kaart, met integratie van de Twitter API voor relevante tweets. Ontwikkeld in C# en WPF voor een robuuste en gebruikersvriendelijke interface.\n\nWaarom dit mij professioneel maakt:\n\nGebruik van moderne technologieën: C# en WPF. Integratie van externe API's: Twitter API. Toepassing in maatschappelijke context. Focus op gebruiksvriendelijke interface. Projectmanagement: Volledige applicatieontwikkeling binnen een semester.\n\nDeze punten tonen mijn technische vaardigheden en mijn vermogen om praktische oplossingen te ontwikkelen, essentieel voor professioneel succes.",
      "year": "2022/2023",
      "title": "Wijkagent WPF applicatie",
      "imageSrc": wijkagentImage,
      "logo": wijkagentLogo,
      "techs": [
        { "title": "C#", "Icon": SiCsharp },
        { "title": "WPF", "Icon": FaWpforms },
        { "title": "Github", "Icon": FaGithub },
        { "title": "MySQL", "Icon": SiMysql },
      ]
    },
    {
      "year": "2022",
      "description": "Voor het HBO-ICT semester Robotica maakten we een Orderpicker-robot. We programmeerden een Arduino in de Arduino IDE om met sensorwaarden orders in de stelling te herkennen. Deze logica werd doorgestuurd naar een Java-applicatie waarin gebruikers orders konden aanmaken, volgen met het TSP-algoritme, en facturen genereren.\n\nDit project toont mijn professionaliteit aan door mijn brede technische vaardigheden in zowel hardware als software, mijn vermogen om complexe problemen op te lossen, en mijn vaardigheid in systeemintegratie en projectmanagement.",
      "title": "Project Orderpicker",
      "imageSrc": orderPickerImage,
      "logo": orderPickerLogo,
      "techs": [
        { "title": "Java", "Icon": FaJava },
        { "title": "MySQL", "Icon": SiMysql },
        { "title": "GitHub", "Icon": FaGithub },
        { "title": "Arduino", "Icon": SiArduino}
      ]
    },
    {
      "description": "NerdyGadgets is een gespecialiseerde webshop voor kleding, gadgets en speelgoed, gericht op ICT-professionals. Deze webshop heb ik ontwikkeld tijdens mijn HBO-ICT opleiding, wat aantoont dat ik in staat ben om op maat gemaakte webshops te bouwen die voldoen aan specifieke doelgroepbehoeften.\n\nBij de ontwikkeling van NerdyGadgets heb ik gebruikgemaakt van PHP, HTML, JavaScript en Bootstrap. Door deze combinatie van technologieën heb ik een robuuste, gebruiksvriendelijke en visueel aantrekkelijke webshop gecreëerd, wat mijn veelzijdige technische vaardigheden onderstreept. Het beheren van dit project van concept tot voltooiing toont mijn organisatorische en projectmanagementvaardigheden, inclusief planning, uitvoering en testen om te voldoen aan alle eisen.\n\nTijdens de ontwikkeling heb ik diverse uitdagingen overwonnen, zoals het integreren van functies en optimaliseren van de gebruikerservaring, wat mijn probleemoplossend vermogen benadrukt. Het toepassen van moderne webontwikkelingstechnieken garandeert dat de webshop veilig, schaalbaar en onderhoudbaar is, wat mijn inzet voor kwaliteit en kennis van best practices aantoont.\n\nDeze aspecten laten zien dat ik zowel de technische vaardigheden als de professionele competenties heb om een succesvolle webshop te bouwen en projecten effectief te beheren. Ik ben ervan overtuigd dat ik dezelfde expertise en toewijding kan bieden bij het ontwikkelen van een op maat gemaakte webshop voor uw specifieke behoeften.",
      "year": "2021/2022",
      "title": "NerdyGadgets Webshop",
      "imageSrc": nerdyGadgetsImage,
      "logo": nerdyGadgetsLogo,
      "techs": [
        { "title": "PHP", "Icon": FaPhp },
        { "title": "MySQL", "Icon": SiMysql },
        { "title": "HTML 5", "Icon": FaHtml5 },
        { "title": "JavaScript", "Icon": SiJavascript },
        { "title": "JQuery", "Icon": SiJquery },
        { "title": "Bootstrap", "Icon": FaBootstrap },
      ]
    },
    {
      "description": "Tijdens mijn stage bij Recreate Solutions, die onderdeel was van mijn opleiding Applicatie- en mediaontwikkelaar aan het ROC van Twente, heb ik veel geleerd en verschillende taken uitgevoerd. Mijn stageperiode liep van 1 februari 2021 tot 25 juni 2021. Bij Recreate Solutions heb ik voornamelijk gewerkt aan het Step-By-Step-Instructions (SBSI) platform, een SaaS-platform dat handleidingen genereert met 3D-weergaves en bruikbaar is in Augmented Reality. Mijn focus lag op het web-frontend gedeelte. Ik heb fouten getest, gedocumenteerd en opgelost. Voor mijn examenopdracht (PVB) heb ik vier incidentmeldingen geanalyseerd en opgelost. Mijn examenopdracht bestond uit het oplossen van bugs binnen het SBSI platform. Ik heb de styling van het welkomstbericht aangepast door een achtergrondkleur toe te voegen, de grootte en uitlijning van pictogrammen gewijzigd, de schaduw van de dropdown box verwijderd, en de breedte en hoogte van voorbeeldplaatjes aangepast. Naast mijn examenopdracht heb ik ook andere taken uitgevoerd, zoals het werken aan een Laravel project genaamd Tweety, gebaseerd op Twitter, om mijn kennis van Laravel te vergroten. Ik heb een link toegevoegd naar het adres op de login pagina van het SBSI platform, de login pagina responsive gemaakt, en een functie toegevoegd om het wachtwoord zichtbaar te maken tijdens het typen. Op basis van deze ervaring kan ik met trots zeggen dat ik professioneel ben omdat ik gedreven en doelgericht ben. Ik heb duidelijke leerdoelen gesteld en deze succesvol bereikt. Ik ben technisch bekwaam en heb geavanceerde programmeertaken uitgevoerd en problemen opgelost binnen een complex SaaS-platform. Door gebruik van tools zoals Jira heb ik mijn tijd effectief beheerd en mijn projecten tijdig afgerond. Naast mijn hoofdprojecten heb ik aanvullende taken uitgevoerd om mijn vaardigheden te verbreden. Bovendien heb ik nauwkeurig gedocumenteerd werk geleverd, wat essentieel is voor professionele ontwikkeling en teamwerk.",
      "year": "2021",
      "title": "Afstudeerstage bij Recreate",
      "imageSrc": recreateImage,
      "logo": recreateLogo,
      "techs": [
        { "title": "PHP", "Icon": FaPhp },
        { "title": "MySQL", "Icon": SiMysql },
        { "title": "Laravel", "Icon": FaLaravel },
        { "title": "HTML 5", "Icon": FaHtml5 },
        { "title": "JavaScript", "Icon": SiJavascript },
        { "title": "Tailwind CSS", "Icon": SiTailwindcss },
        { "title": "JQuery", "Icon": SiJquery },
        { "title": "GitHub", "Icon": FaGithub },
      ]
    },
    {
        "description": "Tijdens mijn stage bij Fiwihex B.V. heb ik waardevolle ervaring opgedaan en diverse taken uitgevoerd. Hier is een overzicht van mijn werkzaamheden: Programmeren met PHP: Mijn voornaamste leerdoel was het verbeteren van mijn PHP-programmeervaardigheden. Ik heb aanzienlijke tijd besteed aan het ontwikkelen van de code voor een bootloader, een programma dat de software van de microcontroller in de warmtewisselaars bijwerkt. Deze uitdagende taak heeft me veel geleerd over het programmeren van sectoren in het flashgeheugen van een microcontroller. Daarnaast heb ik tabellen en grafieken gemaakt om de efficiëntie van verschillende apparaten te analyseren. Ontwikkelen van een nieuwe bootloader: Ik heb de oude bootloadercode bestudeerd en aangepast om een nieuwe, efficiëntere versie te creëren. Dit omvatte het aanmaken van een nieuwe MySQL-database voor updateaanvragen en het testen van de code met een testapparaat om ervoor te zorgen dat alles correct werkte. Mijn stage bij Fiwihex was een waardevolle ervaring waarin ik mijn programmeervaardigheden heb verfijnd en veel heb geleerd over het werken met microcontrollers en het ontwikkelen van technische oplossingen. Als zzp'er ben ik nu klaar om mijn expertise en toewijding in te zetten voor het ontwikkelen van op maat gemaakte technische oplossingen voor uw bedrijf.",
      "year": "2018",
      "title": "Stage Fiwihex",
      "imageSrc": fiwihexImage,
      "logo": fiwihexLogo,
      "techs": [
        { "title": "PHP", "Icon": FaPhp },
        { "title": "MySQL", "Icon": SiMysql },
        { "title": "HTML 5", "Icon": FaHtml5 },
        { "title": "JavaScript", "Icon": SiJavascript },
        { "title": "CSS", "Icon": FaCss3 },
        { "title": "JQuery", "Icon": SiJquery },
        { "title": "Bootstrap", "Icon": FaBootstrap },
      ]
    },
    // Voeg hier andere projecten toe indien nodig
  ]
};

export default projectProps;
