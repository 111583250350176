import React, { useState, useEffect } from 'react';
import { FaCalendar } from 'react-icons/fa';
import TechCard from '../components/TechCard';

function ProjectDetails({ project }) {
    const [circles, setCircles] = useState([]);
    const { year, title, imageSrc, description, logo, techs, links } = project;

    useEffect(() => {
        const generateCircles = () => {
            const circlesArray = [];
            const fixedCircles = [

            ];
            setCircles([...circlesArray, ...fixedCircles]);
        };
        generateCircles();
    }, []);

    const uselinks = links && links.length > 0;

    return (
        <div className=' overflow-clip font-raleway flex flex-col lg:flex-row'>
            <div className='w-full z-10 p-3 lg:w-2/5 lg:p-10'>
                <img src={imageSrc} alt={title} className="w-full self-center rounded-xl" />
            </div>
            <div className='z-10 w-full lg:p-10 lg:w-3/5 p-4'>
                <div className='flex items-center justify-between w-full'>
                    <h1 className='lg:text-4xl w-3/4 font-bold text-blue-900 text-xl'>{title}</h1>
                    <img
                        src={logo}
                        alt={title}
                        className="hover:rotate-12 transition-all duration-500 h-12 lg:h-16 max-w-64 rounded-lg mr-2 p-2"
                    />
                </div>
                <div className="z-10 items-center flex lg:space-x-5 space-x-2">
                    <FaCalendar className="lg:text-xl text-blue-950 -mr-1" />
                    <div className="lg:text-lg text-xs text-blue-950">{year}</div>
                </div>
                <p className='z-10 text-black mt-5 w-full lg:text-xl text-sm'>
                    {description}
                </p>
                <div className='z-10 w-full flex-col mt-2'>
                    {uselinks && (
                        <>
                            <p className='text-blue-900 mt-5 w-3/4 lg:text-xl font-bold'>Links:</p>
                            <div className='flex items-center'>
                                {links.map((link, index) => (
                                    <a
                                        href={link.url}
                                        target='_blank'
                                        rel='noreferrer'
                                        key={index}
                                        className='text-blue-700 underline hover:text-blue-800'
                                    >
                                        {link.title}
                                    </a>
                                ))}
                            </div>
                        </>
                    )}
                    <p className='text-blue-900 lg:mt-5 mt-2 w-3/4 lg:text-xl font-bold'>Gebruikte technieken:</p>
                </div>
                <div className='w-full overflow-x-scroll scrollbar-small items-center self-center '>
                    <div className='flex space-x-4 lg:space-x-12 py-4 min-w-max items-center'>
                        {techs.map((tech, index) => (
                            <TechCard
                                small={true}
                                key={index}
                                Icon={tech.Icon}
                                title={tech.title}
                            />
                        ))}
                    </div>
                </div>
            </div>
            {circles.map((circle, index) => (
                <div
                    key={index}
                    className=" absolute z-0 pulsate circle"
                    style={{
                        opacity: 0.5,
                        width: `${circle.size}vw`,
                        height: `${circle.size}vw`,
                        left: `${circle.left}%`,
                        top: `${circle.top}%`,
                        background: circle.backgroundColor,
                    }}
                />
            ))}
        </div>
    );
}

export default ProjectDetails;
