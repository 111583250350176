import React from 'react';
import PricingCard from '../components/PricingCard';

function SkillsPage(props) {
  return (
    <div className='flex flex-row w-full mt-20 mb-20'>

      <div className='w-5/12 p-10'>
        <h1 className='text-4xl text-white'>{props.title} prijslijst</h1>
        <p className='text-white mt-5 text-xl'>
          Kies de ideale betalingsoptie voor uw project: een uurtarief voor flexibiliteit bij kortere projecten of een vast bedrag voor duidelijke en voorspelbare kosten bij grotere projecten. Onze flexibele betalingsstructuur garandeert dat uw budget altijd goed wordt besteed.
        </p>
      </div>
      <div className='w-7/12 overflow-x-scroll scrollbar-hide'>
        <div className='flex space-x-4 p-4 min-w-max '>
          {props.hourly &&
            <PricingCard title={"Uurtarief"} price={props.hourly.price} undertitle={props.hourly.undertitle} positives={props.hourly.positives} />
          }
          {props.fixed_prices && props.fixed_prices.map((fixed_price, index) => (
            <>
              <PricingCard variant={2} title={fixed_price.title} price={fixed_price.price} positives={fixed_price.positives} />
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SkillsPage;
