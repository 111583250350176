import React, { useState, useEffect } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import TechCard from '../components/TechCard';


function SingleExpertise(props) {
    const [circles, setCircles] = useState([]);

    useEffect(() => {
        const generateCircles = () => {
            const circlesArray = [];
            const fixedCircles = [
                { size: 45, left: -8, top: -5, backgroundColor: 'linear-gradient(90deg, #4BAFFF, #70B9F3, #A1C6E3)' },
            ];
            setCircles([...circlesArray, ...fixedCircles]);
        };
        generateCircles();
    }, []);

    const handleClick = (data) => {
        localStorage.setItem('contactFormData', JSON.stringify(data));
    };

    return (
        <div className='font-raleway flex flex-col w-full lg:mt-20 lg:mb-20'>
            <div className='z-10 w-full lg:p-10 p-5'>
                <h1 className='text-4xl  font-bold text-blue-950'>{props.title}</h1>
                <p style={{whiteSpace: "pre-wrap"}}
                className='text-blue-950 mt-5 w-full lg:text-xl text-sm lg:mb-16 mb-5'>
                    {props.uitleg}
                </p>
                <a
                    onClick={() => handleClick({ category: props.category, message: props.message })}
                    href="/contact"
                    className="group flex
                hover:scale-105  transition-transform duration-300 
                flex-row bg-opacity-80 relative text-white py-6 w-full lg:w-6/12 px-5 items-center rounded-xl">
                    <FaPaperPlane className="text-3xl mr-6 z-10 w-10 h-10" />
                    <span className="relative z-10 lg:text-3xl w-full text-lg">Neem contact op over {props.title}!</span>
                    <div className="z-0 bg-gradient-to-r 
                    group-hover:from-blue-800 group-hover:to-cyan-800 group-hover:via-indigo-600
                    group-hover:opacity-100 transition-all duration-300
                    border border-blue-300 text-xl from-[#0047FF] to-[#3F75FF] absolute inset-0 opacity-80 rounded-xl"></div>
                </a>
            </div>
            <div className='w-11/12 lg:-ml-12 -ml-6 overflow-x-scroll scrollbar-small items-center self-center lg:mt-0 mb-44 lg:mb-0'>
                <div className='lg:flex hidden  space-x-6 lg:space-x-12 p-4 min-w-max items-center  '>
                    {props.techs.map((tech, index) => (
                        <TechCard
                        
                            key={index}
                            Icon={tech.Icon}
                            title={tech.title}
                        />
                    ))}
                </div>
                <div className='flex lg:hidden  space-x-6 lg:space-x-12 p-4 min-w-max items-center  '>
                    {props.techs.map((tech, index) => (
                        <TechCard
                            small={true}
                            key={index}
                            Icon={tech.Icon}
                            title={tech.title}
                        />
                    ))}
                </div>
                {circles.map((circle, index) => (
                    <div
                        key={index}
                        className="absolute -z-0 pulsate circle"
                        style={{
                            opacity: 0.5,
                            width: `${circle.size}vw`,
                            height: `${circle.size}vw`,
                            left: `${circle.left}%`,
                            top: `${circle.top}%`,
                            background: circle.backgroundColor,
                        }}
                    />
                ))}
            </div>
        </div>
    );
}

export default SingleExpertise;
