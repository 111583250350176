import React from 'react';
import PricingCard from '../components/PricingCard';

function PricingPage(props) {
  
  return (
    <div className=" overflow-hidden z-10 font-raleway flex flex-col lg:flex-row w-full   relative">

      <div className="z-10 lg:w-5/12 p-10 lg:p-20 lg:mt-20  ">
        <h1 className="text-4xl text-blue-600">Uurtariefen</h1>
        <p className="text-gray-600 mt-5 text-xl w-full lg:w-10/12">
          Bij Vinci Code Solutions hanteren we 2 tariefen: één voor softwareoplossingen en één voor data science en machine learning oplossingen.
          Hier ziet u de tariefen en de diensten die wij aanbieden.
        </p>
      </div>

      <div className="lg:mt-20 mb-20 lg:w-7/12 overflow-x-auto scrollbar-hide">
        <div className="flex flex-col sm:space-y-0 space-y-11 items-center sm:flex-row justify-center sm:justify-evenly p-4 min-w-full lg:min-w-max">
          <PricingCard title="Software development" price="60"
            category={"Desktop Development"}
            message={"Hallo Ewaldo, ik wil graag contact opnemen om een op maat gemaakte applicatie te laten ontwikkelen. Kun je me daarbij helpen?"}
            undertitle="Voor softwareontwikkeling, webapplicaties en mobiele apps"
            positives={["Webapplicaties", "Mobiele apps", "Desktop applicaties", "Op maat gemaakte applicaties"]} />

          <PricingCard title="Machine Learning & Data Science"
            category={"Data Science"}
            message={"Hallo Ewaldo, ik wil graag contact opnemen over mijn data science of machine learning project. Kun je me daarbij helpen?"}
            undertitle="Voor datagedreven advies en mcahine learning innovaties"
            price="65" positives={["Machine Learning", "AI", "Datagedreven advies", "Datagedreven innovaties"]} />
        </div>
      </div>

      <div
        className="hidden lg:block absolute z-0 pulsate circle"
        style={{
          opacity: 0.2,
          width: `45vw`,
          height: `45vw`,
          left: `-2%`,
          top: '-30%',
          background: 'linear-gradient(90deg, #4BAFFF, #70B9F3, #A1C6E3)',
        }}
      />
      <div
        className=" lg:hidden absolute z-0 circle "
        style={{
          opacity: 0.2,
          width: `150vw`,
          height: `150vw`,
          left: '-25%',
          top: '-10%',
          background: 'linear-gradient(90deg, #4BAFFF, #70B9F3, #A1C6E3)',
        }}
      />
    </div>
  );
}

export default PricingPage;
