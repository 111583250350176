import React from 'react';
import { FaCalendar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Project = ({ year, title, imageSrc, description, logo, techs, links = [] }) => {
    const navigate = useNavigate();
    const link = title.toLowerCase().replace(/ /g, '-');

    const handleDivClick = () => {
        navigate(`/project/${link}`);
    };

    return (
        <div
            onClick={handleDivClick} 
            className="z-10  cursor-pointer relative font-raleway bg-white rounded-lg group shadow-2xl hover:scale-95 lg:hover:scale-105 transition-all duration-500 hover:cursor-pointer ">
            <div className="z-10 flex flex-col md:flex-row md:items-center">
                <div className="z-10 flex flex-col w-full">
                    <div className='lg:pb-40 pb-24  rounded-t-lg'
                    style={{
                        backgroundImage: `url(${imageSrc})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundBlendMode: 'overlay',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <div className=" rounded-t-lg lg:px-6 px-4 flex flex-row items-center justify-between mb-4 bg-white group-hover:bg-blue-100 group-hover:bg-opacity-50 bg-opacity-50 py-2">
                            <h2 className="lg:text-2xl font-semibold text-left">{title}</h2>
                            <img
                                src={logo}
                                alt={title}
                                className="hover:rotate-180 transition-all duration-500 h-10 lg:h-16 max-w-64 rounded-lg cursor-pointer"
                            />
                        </div>
                    </div>
                    <div className='lg:p-6 p-4 group-hover:bg-blue-50 group-hover:rounded-b-lg '>
                        <div className="items-center flex lg:space-x-5 space-x-2">
                            <FaCalendar className="lg:text-xl font-bold text-black lg:-mr-4 -mr-1" />
                            <div className="lg:text-lg text-xs font-bold ">{year}</div>
                        </div>
                        <div className="flex flex-col md:flex-row ">
                            <div className="w-full md:w-9/12 flex-1 flex-col lg:mt-0 mt-6 text-lg">
                                <p className="text-sm lg:pr-20 line-clamp-5">{description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Project;
