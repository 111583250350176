import ml from '../assets/images/pexels-googledeepmind-17483874.jpg';
import ds from '../assets/images/ds.jpg';
import desktop from '../assets/images/desktop.jpg';
import mobile from '../assets/images/mobile.jpg';
import web from '../assets/images/web.jpg';
import other from '../assets/images/other.jpg';

import React, { useState, useEffect } from 'react';
export default function HireMe() {

    const Box = ({ title, text, initialWidth, finalWidth, image, link }) => {
        const [width, setWidth] = useState("w-3/12");

        useEffect(() => {
            // Trigger the width expansion after the component mounts
            const timer = setTimeout(() => {
                setWidth(finalWidth);
            }, 100); // Delay to ensure animation plays smoothly

            // Cleanup timer if the component unmounts
            return () => clearTimeout(timer);
        }, [finalWidth]);

        return (
            <a
                href={link}
                className={`${width} self-end bg-black bg-opacity-40 border-opacity-70 border-b-black border-b-2 shadow-2xl hover:bg-opacity-20 h-34 lg:hover:scale-105 hover:scale-95  transition-transform duration-300 p-5 rounded-lg mt-5`}
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundBlendMode: 'overlay',
                    width: width,

                    transition: 'width 1.5s ease-out, transform 0.3s ease-out' // Adjusted duration to 3 seconds
                }}
            >
                <h1 className="text-white text-4xl">{title}</h1>
                <p className="text-white text-sm font-light my-2">{text}</p>
            </a>
        );
    }


    const HireMe = () => {
        return (
            <section className="mt-10 flex justify-end flex-col mb-10 w-full">
                <h1 className="lg:text-2xl lg:text-right  text-3xl font-bold text-center lg:font-normal  text-white mr-16 mb-10">Welke uitdaging gaan we samen aanpakken?</h1>
                <div className=" w-full flex lg:flex-row flex-col">
                    {/* for the text */}
                    <div className="lg:w-6/12">
                        <h1 className=" ml-20 text-3xl font-base text-white">Waarom Met Mij Samenwerken?</h1>
                        <p className="ml-20 text-white text-base italic mt-5 w-4/6">
                            Als gepassioneerde professional in softwareontwikkeling en data science, zijn wij er om uw visie tot leven te brengen en uw projecten naar nieuwe hoogten te tillen. Met een focus op kwaliteit, creativiteit en samenwerking, bieden wij op maat gemaakte oplossingen die voldoen aan uw unieke behoeften en doelstellingen.</p>
                    </div>
                    {/* for machine learning,ai and data science */}
                    <div className="lg:w-6/12 flex justify-end flex-col p-4 lg:p-0 mr-16">
                        {/* for machine learning,ai */}
                        <Box image={ml} title="Machine Learning & AI" 
                        initialWidth="lg:w-5/12" 
                        finalWidth="lg:w-10/12" 
                        link="/pricing/ai" 
                        text="Geef uw bedrijf een boost naar ongekende hoogten door mijn expertise in machine learning en AI te benutten. Ontdek de tarieven voor deze innovatieve diensten en bereid u voor op een revolutie in uw bedrijfsstrategie." />
                        {/* for data science */}
                        <Box
                            image={ds}
                            title="Data Science"
                            initialWidth="lg:w-5/12"
                            finalWidth="lg:w-11/12"
                            link="/pricing/ds"
                            text="Transformeer uw data in waardevolle inzichten met mijn expertise in data science. Ontdek hoe mijn diensten uw bedrijfsstrategie kunnen vernieuwen en uw organisatie naar nieuwe hoogten kunnen brengen."
                        />

                    </div>

                </div>
                <div className="lg:mr-16 px-4 lg:px-0 justify-end flex flex-col">
                    {/* for desktop */}
                    <Box
                        image={desktop}
                        title="Desktop Applicaties"
                        initialWidth="lg:w-5/12"
                        finalWidth="lg:w-7/12"
                        link="/pricing/desktop"
                        text="Ontdek hoe mijn deskundigheid in desktopapplicaties uw projecten kan verbeteren. Met mijn ervaring kan ik uw ideeën omzetten in goed presterende, gebruiksvriendelijke desktoptoepassingen die voldoen aan uw zakelijke behoeften."
                    />
                    {/* for websites and webapps */}
                    <Box
                        image={web}
                        title="Websites"
                        initialWidth="lg:w-5/12"
                        finalWidth="lg:w-8/12"
                        link="/pricing/web"
                        text="Met mijn expertise in website- en webapplicatie-ontwikkeling kan ik uw projecten naar nieuwe hoogten tillen. Laat mij u helpen bij het creëren van krachtige online platformen die uw bedrijf onderscheiden en uw klanten aanspreken, met oplossingen die naadloos aansluiten op uw zakelijke visie."
                    />                   
                     {/* for mobile apps */}
                    <Box
                        image={mobile}
                        title="Mobiele Apps"
                        initialWidth="lg:w-5/12"
                        finalWidth="lg:w-9/12"
                        link="/pricing/mobile"
                        text="Door mijn diepgaande ervaring in mobiele app-ontwikkeling kan ik uw projecten een geheel nieuwe dimensie geven en uw bedrijf naar ongekende successen leiden. Samen kunnen we innovatieve en gebruiksvriendelijke mobiele apps creëren die niet alleen aan uw zakelijke doelen voldoen, maar ook de gebruikers betoveren en uw merk versterken."
                        />
                    <Box
                        image={web}
                        title="Ecommerce"
                        initialWidth="lg:w-5/12"
                        finalWidth="lg:w-10/12"
                        link="/pricing/ecommerce"
                        text=" Ecommerce is een van de snelst groeiende sectoren in de digitale wereld. Met mijn expertise in ecommerce-ontwikkeling kan ik u helpen bij het creëren van krachtige online winkels die uw bedrijf naar nieuwe hoogten tillen. Ontdek hoe ik u kan helpen bij het realiseren van uw ecommerce-dromen met op maat gemaakte oplossingen die perfect passen bij uw bedrijfsbehoeften."
                    />

                    <Box
                        image={other}
                        title="Andere Diensten"
                        initialWidth="lg:w-5/12"
                        finalWidth="lg:w-11/12"
                        link="/pricing/other"
                        text="Ontdek hoe mijn deskundigheid in diverse diensten uw projecten kan verbeteren. Of het nu gaat om webontwikkeling, cloudoplossingen, of iets anders, ik kan helpen bij het realiseren van uw digitale doelen met op maat gemaakte oplossingen die perfect passen bij uw bedrijfsbehoeften."
                    />

                </div>

            </section>
        );
    };

    return HireMe();
}