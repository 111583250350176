import React, { useEffect, useState } from 'react';
import CertificaatSupplement from '../assets/pdf/Applicatie- en mediaontwikkelaar.pdf';
import ApplicatieMedia from '../assets/pdf/Mbo Applicatie- en mediaontwikkelaar-2.pdf';
import Propedeuse from '../assets/pdf/HBO Bachelor HBO-ICT Propedeuse bachelor (Information & Communication Technology).pdf';
import graduation from '../assets/images/graduation.png';
import certificate from '../assets/images/certificate.jpeg';
import propedeuse from '../assets/images/propedeuse.jpeg';
import graduation2 from '../assets/images/graduation2.png';
import mbodiploma from '../assets/images/test.jpeg';
import { GrDownload } from "react-icons/gr";

const Certificates = () => {
    const [circles, setCircles] = useState([]);

    useEffect(() => {
        const generateCircles = () => {
            const circlesArray = [];
            const fixedCircles = [
                { size: 50, left: -10, top: -7, backgroundColor: 'linear-gradient(145deg, #B3F0FE, #8AD1F1, #B3F0FE)' },
                { size: 7, left: 50, top: 5, backgroundColor: 'linear-gradient(145deg, #B3F0FE, #8AD1F1, #B3F0FE)' },
                { size: 9, left: 57, top: 12, backgroundColor: 'linear-gradient(145deg, #B3F0FE, #8AD1F1, #B3F0FE)' },
                { size: 10, left: 62, top: 39, backgroundColor: 'linear-gradient(145deg, #4BAFFF, #3A91CC, #2A72AA)' },
                { size: 39, left: 65, top: 35, backgroundColor: 'linear-gradient(145deg, #4BAFFF, #3A91CC, #2A72AA)' },
                { size: 4, left: 54, top: 39, backgroundColor: 'linear-gradient(145deg, #4BAFFF, #3A91CC, #2A72AA)' },
                { size: 38, left: -10, top: 70, backgroundColor: 'linear-gradient(145deg, #D4B3FE, #B394E6, #9E84D4)' },
                { size: 8, left: 48, top: 75, backgroundColor: 'linear-gradient(145deg, #D4B3FE, #B394E6, #9E84D4)' },
                { size: 5, left: 60, top: 85, backgroundColor: 'linear-gradient(145deg, #D4B3FE, #B394E6, #9E84D4)' },
            ];

            setCircles([...circlesArray, ...fixedCircles]);
        };

        generateCircles();
    }, []);

    return (
        <section className="relative overflow-hidden text-center flex flex-col font-raleway">
            <div className="z-10 flex flex-col lg:flex-row items-center lg:mt-12 mt-6 justify-between h-full px-3  lg:px-16">
                <div
                    className="lg:w-1/2 w-full  lg:px-0 flex justify-center lg:justify-start shadow-2xl relative rounded-3xl"
                    style={{
                        backgroundImage: `url(${certificate})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'left',
                        backgroundRepeat: 'no-repeat',
                        height: '70vh',

                    }}
                />
                <div className='flex flex-col items-center mt-8 lg:mt-0 lg:ml-8'>
                    <h1 className="text-lg lg:text-4xl text-blue-950 font-bold">
                        Certificaatsupplement applicatie- mediaontwikkelaar
                    </h1>
                    <p className='w-full lg:w-2/4 text-blue-950 mt-4 lg:mt-12 text-sm lg:text-base'>
                        Het Certificaatsupplement voor de kwalificatie van Applicatie- en mediaontwikkelaar biedt een gedetailleerde beschrijving van de vaardigheden, competenties en beroepsmogelijkheden die geassocieerd zijn met dit diploma van het Nederlandse beroepsonderwijs.
                    </p>
                    <div className='flex flex-row items-center'>
                        <a href={CertificaatSupplement} download="certificaat_supplement_applicatie_mediaontwikkelaar.jpg" className='bg-[#000A66] flex flex-row items-center justify-between text-white px-4 py-2 mt-8 rounded-xl hover:bg-blue-900'>
                            <GrDownload className='w-5 h-5 text-white' />
                            <p className='ml-2 lg:ml-6 font-bold text-lg font-raleway'>Download</p>
                        </a>
                    </div>
                </div>
            </div>
            <img src={graduation} alt="hero" className="z-10 w-full self-center lg:w-1/4 lg:self-end  lg:-mt-36 " />

            <div className=" z-10 flex flex-col lg:flex-row items-center  lg:mt-22 justify-between h-full px-2 lg:px-16">
                <div className='lg:mt-0 mt-12 order-2 lg:order-none  flex flex-col items-center lg:mr-8'>
                    <h1 className="text-lg lg:text-4xl text-blue-950 font-bold">
                        HBO Bachelor HBO-ICT Propedeuse
                    </h1>
                    <p className='w-full lg:w-2/4 text-blue-950 mt-2 text-sm lg:text-base lg:mt-12'>
                        Een HBO Bachelor HBO-ICT Propedeuse is het eerste jaar van een HBO Bachelor opleiding in de richting van Information & Communication Technology in Nederland. Het is een onderwijskwalificatie uitgegeven door hogescholen, in dit geval de Christelijke Hogeschool Windesheim.
                    </p>
                    <a href={Propedeuse} download="hbo_bachelor_hbo_ict_propedeuse.pdf" className='bg-[#000A66] flex flex-row items-center justify-between text-white px-4 py-2 mt-8 rounded-xl hover:bg-blue-900'>
                        <GrDownload className='w-5 h-5 text-white' />
                        <p className='ml-2 lg:ml-6 font-bold text-lg font-raleway'>Download</p>
                    </a>
                </div>
                <div
                    className="lg:w-1/2 w-full flex justify-center lg:justify-start shadow-2xl relative rounded-3xl mt-8 lg:mt-0"
                    style={{
                        backgroundImage: `url(${propedeuse})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'right',
                        backgroundRepeat: 'no-repeat',
                        height: '70vh',

                    }}
                />
            </div>
            <img src={graduation2} alt="hero" className="z-10 w-64 self-center lg:w-1/4 lg:self-start mt-8 lg:ml-12 lg:-mt-36 object-cover" />

            <div className="z-10 flex flex-col lg:flex-row items-center mt-12 lg:mt-22 justify-between h-full px-2 lg:px-16 mb-24">
                <div
                    className="lg:w-1/2 w-full flex justify-center lg:justify-start shadow-2xl relative rounded-3xl"
                    style={{
                        backgroundImage: `url(${mbodiploma})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'left',
                        backgroundRepeat: 'no-repeat',
                        height: '70vh',
                    }}
                />
                <div className=' order-2 lg:order-1 flex flex-col items-center mt-8 lg:mt-0 lg:ml-8'>
                    <h1 className="text-lg lg:text-4xl text-blue-950 font-bold">
                        Diploma Mbo Applicatie- en mediaontwikkelaar
                    </h1>
                    <p className='w-full lg:w-2/4 text-blue-950 mt-4 lg:mt-12 text-sm lg:text-base'>
                        Dit is mijn Mbo-diploma voor de opleiding Applicatie- en mediaontwikkelaar is een beroepsopleiding op niveau 4. Het diploma, uitgegeven door het Regionaal Opleidingencentrum van Twente. Het diploma is op NLQF 4 / EQF 4 niveau, wat overeenkomt met het hogere middelbaar beroepsonderwijs. Het behoort tot het kwalificatiedossier Applicatieontwikkeling.
                    </p>
                    <div className='flex flex-row items-center'>
                        <a href={ApplicatieMedia} download="diploma_mbo_applicatie_mediaontwikkelaar.pdf" className='bg-[#000A66] flex flex-row items-center justify-between text-white px-4 py-2 mt-8 rounded-xl hover:bg-blue-900'>
                            <GrDownload className='w-5 h-5 text-white' />
                            <p className='ml-2 lg:ml-6 font-bold text-lg font-raleway'>Download</p>
                        </a>
                    </div>
                </div>
            </div>

            {circles.map((circle, index) => (
                <div
                    key={index}
                    className="absolute z-0 pulsate circle"
                    style={{
                        width: `${circle.size}vw`,
                        height: `${circle.size}vw`,
                        left: `${circle.left}%`,
                        top: `${circle.top}%`,
                        background: circle.backgroundColor,
                    }}
                />
            ))}
        </section>
    );
};

export default Certificates;
