import React, { useState, useEffect } from 'react';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import Logo from '../components/Logo';
import WebFont from 'webfontloader';

// Component voor navigatieknoppen
const NavButton = ({ text, onClick }) => (
  <a href="/contact" className="bg-blue-600 text-white font-semibold text-md p-1 px-2 shadow-lg rounded-lg flex items-center hover:scale-105 duration-300 hover:bg-blue-900" onClick={onClick}>
    {text}
  </a>
);

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['STIX Two Text', 'Raleway']
      }
    });

    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    <header className="font-raleway shadow-lg text-xl top-0 z-20 bg-gradient-to-r from-blue-50 via-cyan-50 to-red-50">
      <div className="p-1 flex items-center justify-between">
        <div className="justify-start items-center">
          <NavLink to="/home" className="hidden lg:flex items-center hover:animate-pulse flex-row text-base ml-40">
            <Logo width={130} height={70} />
            <div className="flex flex-col -ml-6">
              <h1 style={{ fontFamily: 'STIX Two Text' }} className="text-blue-950 text-4xl text-center">VCS</h1>
              <p style={{ fontFamily: 'Raleway' }} className="text-blue-950 text-sm -mt-2 text-center">Vinci Code Solutions</p>
            </div>
          </NavLink>
        </div>
        <button className="lg:hidden text-gray-700" onClick={toggleMenu}>
          <AiOutlineMenu className="w-10 h-10 p-2" />
        </button>
        <div className="lg:hidden flex justify-center flex-grow">
          <NavLink to="/home" className="hover:animate-pulse flex flex-row items-center -ml-12">
            <Logo width={100} height={65} />
          </NavLink>
        </div>
        <div className="hidden lg:flex flex-grow justify-end mr-6">
          <nav className="flex items-center space-x-3 text-sm">
            <NavLink to="/home" className={({ isActive }) => isActive ? "font-bold transition duration-300" : "text-blue-950 hover:font-semibold transition duration-300"}>Home</NavLink>
            <NavLink to="/werkwijze" className={({ isActive }) => isActive ? "font-bold transition duration-300" : "text-blue-950 hover:font-semibold transition duration-300"}>Werkwijze</NavLink>
            <NavLink to="/works" className={({ isActive }) => isActive ? "font-bold transition duration-300" : "text-blue-950 hover:font-semibold transition duration-300"}>Ervaringen</NavLink>
            <NavLink to="/certificates" className={({ isActive }) => isActive ? "font-bold transition duration-300" : "text-blue-950 hover:font-semibold transition duration-300"}>Certificaten</NavLink>
            <NavLink to="/expertises" className={({ isActive }) => isActive ? "font-bold transition duration-300" : "text-blue-950 hover:font-semibold transition duration-300"}>Expertises</NavLink>
            <NavLink to="/prijzen" className={({ isActive }) => isActive ? "font-bold transition duration-300" : "text-blue-950 hover:font-semibold transition duration-300"}>Prijzen</NavLink>
            <NavLink to="/about" className={({ isActive }) => isActive ? "font-bold transition duration-300" : "text-blue-950 hover:font-semibold transition duration-300"}>Over ons</NavLink>
            <NavButton text="Neem contact op" />
          </nav>
        </div>
      </div>
      <div
        onClick={toggleMenu}
        className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
        <div className={`fixed left-0 top-0 bg-white w-2/3 h-full p-4 transition-transform duration-300 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
          <button className="text-gray-700 mb-4 flex items-center w-full hover:bg-gray-100" onClick={toggleMenu}>
            <AiOutlineClose className="w-5 h-5 mr-10" />
            <p>Sluiten</p>
          </button>
          <nav className="flex flex-col space-y-4">
            <NavLink to="/home" className={({ isActive }) => isActive ? "font-bold transition duration-300" : "text-blue-950 hover:font-semibold transition duration-300"}>Home</NavLink>
            <NavLink to="/werkwijze" className={({ isActive }) => isActive ? "font-bold transition duration-300" : "text-blue-950 hover:font-semibold transition duration-300"}>Werkwijze</NavLink>
            <NavLink to="/works" className={({ isActive }) => isActive ? "font-bold transition duration-300" : "text-blue-950 hover:font-semibold transition duration-300"}>Ervaringen</NavLink>
            <NavLink to="/certificates" className={({ isActive }) => isActive ? "font-bold transition duration-300" : "text-blue-950 hover:font-semibold transition duration-300"}>Certificaten</NavLink>
            <NavLink to="/expertises" className={({ isActive }) => isActive ? "font-bold transition duration-300" : "text-blue-950 hover:font-semibold transition duration-300"}>Expertises</NavLink>
            <NavLink to="/prijzen" className={({ isActive }) => isActive ? "font-bold transition duration-300" : "text-blue-950 hover:font-semibold transition duration-300"}>Prijzen</NavLink>
            <NavLink to="/about" className={({ isActive }) => isActive ? "font-bold transition duration-300" : "text-blue-950 hover:font-semibold transition duration-300"}>Over ons</NavLink>
            <NavButton text="Neem contact op" />
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
