import React from 'react';

const LongWhiteLine = () => {
  return (
    <div className='bg-gradient-to-r from-[#0071C2] to-[#000A66]shadow-xl via-white'
    style={{
      width: '100%',
      height: '2px', // Adjust the height as needed
      margin: '20px 0', // Adjust margin as needed
    }}></div>
  );
}

export default LongWhiteLine;
