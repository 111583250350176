import React, { useEffect, useState } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import heroImage from '../assets/images/Frame 4.png';
import '../App.css';
import wijkagent from '../assets/images/wijkagent.png';
import nerdygadgets from '../assets/images/projects/SHOP.png';
import datascience from '../assets/images/projects/data.png';
import ai from '../assets/images/projects/chatty.png';
import app from '../assets/images/projects/mobileapp.png';

const MainSection = () => {
  const [circles, setCircles] = useState([]);

  useEffect(() => {
    const generateCircles = () => {
      const fixedCircles = [
        { size: 37, left: -8, top: 22, backgroundColor: 'linear-gradient(145deg, #B3F0FE, #8AD1F1, #5CA9F3)' },
        { size: 7, left: 42, top: 27, backgroundColor: 'linear-gradient(145deg, #B3F0FE, #8AD1F1, #5CA9F3)' },
        { size: 8, left: 48, top: 32, backgroundColor: 'linear-gradient(145deg, #B3F0FE, #8AD1F1, #5CA9F3)' },
        { size: 42, left: 65, top: 37, backgroundColor: 'linear-gradient(145deg, #000A66, #00008B, #0000AA)' },
        { size: 8, left: 52, top: 47, backgroundColor: 'linear-gradient(145deg, #000A66, #00008B, #0000AA)' },
        { size: 5, left: 44, top: 42, backgroundColor: 'linear-gradient(145deg, #000A66, #00008B, #0000AA)' },
        { size: 40, left: -5, top: 53, backgroundColor: 'linear-gradient(145deg, #D4B3FE, #B394E6, #9E84D4)' },
        { size: 8, left: 42, top: 55, backgroundColor: 'linear-gradient(145deg, #D4B3FE, #B394E6, #9E84D4)' },
        { size: 5, left: 55, top: 60, backgroundColor: 'linear-gradient(145deg, #D4B3FE, #B394E6, #9E84D4)' },
        { size: 8.5, left: 64, top: 68, backgroundColor: 'linear-gradient(145deg, #4BAFFF, #3A91CC, #2A72AA)' },
        { size: 39, left: 65, top: 66, backgroundColor: 'linear-gradient(145deg, #4BAFFF, #3A91CC, #2A72AA)' },
        { size: 4, left: 59, top: 67, backgroundColor: 'linear-gradient(145deg, #4BAFFF, #3A91CC, #2A72AA)' },
        { size: 36, left: -5, top: 82, backgroundColor: 'linear-gradient(145deg, #333399, #3333AA, #3333CC)' },
        { size: 7, left: 30, top: 93, backgroundColor: 'linear-gradient(145deg, #333399, #3333AA, #3333CC)' },
        { size: 8, left: 43, top: 89, backgroundColor: 'linear-gradient(145deg, #333399, #3333AA, #3333CC)' },
        { size: 4, left: 32, top: 81, backgroundColor: 'linear-gradient(145deg, #333399, #3333AA, #3333CC)' },
        { size: 4.5, left: 42, top: 84, backgroundColor: 'linear-gradient(145deg, #333399, #3333AA, #3333CC)' }        
      ];

      setCircles([...fixedCircles]);
    };



    generateCircles();
  }, []);
  const handleClick = (data) => {
    localStorage.setItem('contactFormData', JSON.stringify(data));
  };
  return (
    <section className="relative overflow-hidden text-center flex flex-col font-raleway">
      <div className="relative w-full z-10">
        <div className="flex flex-col lg:flex-row w-full z-10">
          <div className="lg:pl-12 w-full lg:p-0 p-2 lg:w-2/6 flex flex-col items-center pt-8 sm:px-0  bg-cover bg-no-repeat bg-hero-pattern lg:bg-none pb-4 relative z-10 bg-[#fbfaff]">
            <div className=" rounded-xl bg-black bg-opacity-50  lg:bg-transparent lg:bg-none p-4 shadow-lg lg:shadow-none">
              <h1 className="lg:text-blue-950 text-white text-2xl text-center font-stix lg:mt-12">Vinci Code Solutions</h1>
              <h2 className="text-lg sm:text-2xl font-semibold  mt-20 text-white lg:text-blue-950 font-raleway">
                Hallo, ik ben Ewaldo. Laat uw ideeën werkelijkheid worden met mijn expertise in
                <span className="text-indigo-400"> Softwareontwikkeling</span>,
                <span className="text-blue-500"> Data science </span> en
                <span className="text-cyan-500"> AI</span>
              </h2>
              <div className="flex p-6 lg:p-0 lg:flex-col felx-row  w-full justify-center  space-y-2 sm:space-y-0 sm:space-x-4 mt-10">
                <a
                  onClick={() => handleClick({ category: 'Algemeen', message: 'Beste Ewaldo, Ik zou graag met je in contact willen komen over mijn ICT-project.' })}
                  href="/contact"
                  className="group shadow-xl bg-gradient-to-r from-blue-600 via-indigo-500 to-cyan-600 hover:from-blue-800 hover:to-cyan-800 hover:via-indigo-600 text-white px-6 sm:px-10 text-sm font-bold py-3 sm:py-5 rounded-lg flex transition-transform transform hover:scale-105 w-full items-center"
                >
                  <FaPaperPlane size="" className=" lg:w-16 lg:h-16 w-8 h-8 pr-2 text-blue-50 group-hover:animate-ping transition-transform" />
                  <p className="text-sm lg:text-3xl w-full font-raleway">Neem contact op</p>
                </a>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-4/6 hidden lg:block relative z-0 bg-[#fbfaff]">
            <img src={heroImage} alt="Ewaldo Çitgez" className="  w-full h-56 sm:h-80 lg:h-full" />
          </div>
        </div>
      </div>
      <div className="text-sm sm:text-base md:text-lg text-gray-700 text-left p-4 sm:pl-10 relative z-10 bg-gradient-to-b from-[#fbfaff] to-[#f9f7ff]">
        <p className="text-blue-950 md:text-lg text-sm md:p-10  p-2 font-medium">
          Hallo, mijn naam is <strong>Ewaldo</strong>. Ik ben een <strong>Software Developer</strong> en <strong>Data Scientist</strong>, gespecialiseerd in het helpen van
          kleine bedrijven en start-ups. Heeft u een <strong>software-, machine learning-</strong> of <strong>data science-oplossing</strong> nodig om uw
          bedrijf naar een hoger niveau te tillen? Dan bent u bij mij aan het juiste adres! Of het nu gaat om het
          ontwikkelen van <strong>op maat gemaakte applicaties</strong>, het implementeren van <strong>machine learning-modellen</strong> of het analyseren
          van uw bedrijfsdata voor waardevolle <strong>inzichten</strong>, ik sta klaar om u te helpen. Samen kunnen we <strong>innovatieve oplossingen </strong>
           creëren die uw bedrijf laten groeien en u een <strong>voorsprong</strong> geven op de concurrentie.
        </p>
      </div>

      {/* Improved Components */}
      <a 
      href="/expertises/desktop"
      className="flex flex-col lg:flex-row items-center mt-20 justify-between h-full z-10 px-8 lg:px-16">
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-start lg:mr-4 lg:p-12 relative bg-contain bg-left bg-no-repeat" style={{ backgroundImage: `url(${wijkagent})`, height: '50vh', maxHeight: '700px' }}>
        </div>
        <h1 className="w-full lg:w-1/2 text-2xl lg:text-4xl align-middle text-center text-blue-950 font-bold mt-8 lg:mt-0">
          Op zoek naar een desktopapplicatie? Laat ons je helpen uw visie te realiseren.
        </h1>
      </a>

      <a 
      href="/expertises/web"
      className="flex flex-col lg:flex-row items-center justify-between h-full z-10 px-8 lg:px-16 mt-20">
        <div className="w-full lg:w-1/2 order-1 lg:order-2 h-64 lg:h-96 relative bg-contain bg-right bg-no-repeat" 
        style={{ backgroundImage: `url(${nerdygadgets})`, height: '50vh', maxHeight: '700px' }}>
        </div>
        <h1 className="order-2 lg:order-1 text-2xl lg:text-4xl text-center text-blue-950 font-bold w-full lg:w-1/3 lg:mr-28 mt-12 lg:mt-0">
          Op zoek naar een op maat gemaakte webapplicatie? Wij regelen het voor u!
        </h1>
      </a>

      <a 
      href="/expertises/ds"
      className="flex flex-col lg:flex-row items-center justify-between h-full z-10 px-8 lg:px-16 mt-20">
        <div className="w-full lg:w-2/5 h-64 relative bg-contain bg-left bg-no-repeat" style={{ backgroundImage: `url(${datascience})`, height: '50vh', maxHeight: '700px' }}>
        </div>
        <h1 className="text-2xl lg:text-4xl text-center text-blue-950 font-bold w-full lg:w-1/3 lg:mr-28 mt-12 lg:mt-0">
          Maak van uw data waardevolle bedrijfsinzichten met onze geavanceerde data-analyses.
        </h1>
      </a>

      <a 
      href="/expertises/ai"
      className="flex flex-row items-center justify-between h-full z-10 px-8 lg:px-16 mt-20">
        <h1 className=" text-xl lg:text-4xl text-center  text-blue-950 font-bold w-full lg:w-2/3">
          Ontdek hoe wij met Machine Learning, zoals Chatbots, uw bedrijf en klanten nieuwe inzichten kunnen bieden.
        </h1>
        <div className="w-full lg:w-1/2 h-64 lg:h-96 relative bg-contain bg-right bg-no-repeat" style={{ backgroundImage: `url(${ai})`, height: '60vh', maxHeight: '700px' }}>
        </div>
      </a>

      <a 
      href="/expertises/mobile"
      className="pb-20 flex flex-col lg:flex-row items-center justify-between h-full z-10 px-8 lg:px-16 mt-20">
        <div className="w-full lg:w-3/4 h-64 lg:h-96 relative bg-contain bg-left bg-no-repeat" style={{ backgroundImage: `url(${app})`, height: '50vh', maxHeight: '700px' }}>
        </div>
        <h1 className="text-2xl lg:text-4xl text-center text-blue-950 font-bold w-full lg:w-1/3 lg:mr-28 mt-8 lg:mt-0">
          Ervaar top prestaties en perfecte integratie met op maat gemaakte Native Applications.
        </h1>
      </a>

      {/* Circles */}
      {circles.map((circle, index) => (
        <div
          key={index}
          className="absolute z-0 pulsate circle"
          style={{
            width: `${circle.size}vw`,
            height: `${circle.size}vw`,
            left: `${circle.left}%`,
            top: `${circle.top}%`,
            background: circle.backgroundColor,
          }}
        />
      ))}
    </section>
  );
};

export default MainSection;
