import React from 'react';
import { FaSearch, FaCode, FaBug, FaDatabase } from 'react-icons/fa';
import { PiBlueprintFill } from 'react-icons/pi';
import { IoRocketSharp } from 'react-icons/io5';
import { TbTargetArrow } from 'react-icons/tb';
import { BsGraphUp } from 'react-icons/bs';
import { LuBrainCircuit } from 'react-icons/lu';
import { HiOutlinePresentationChartBar } from 'react-icons/hi';
import phone from '../assets/images/mobile.jpg';
import code from '../assets/images/image.png';
import datascience from '../assets/images/ds.jpg';
import ml from '../assets/images/mlinfo.png';
import mlana from '../assets/images/mlana.png';
import '../App.css';

const Werkwijze = () => {
    const classnameBoxes = "lg:w-10 lg:h-10 h-5 w-5 text-white"
    return (
        <section className="relative overflow-hidden text-center flex flex-col font-raleway p-2 lg:p-20">
            <div className="flex flex-col lg:flex-row w-full">
                <div className=" hidden lg:block w-full lg:w-2/6 space-y-8 lg:space-y-36">
                    <img src={phone} alt="hero" className="rounded-xl hover:scale-105 transition-all duration-300 w-full object-cover shadow-2xl" />
                    <img src={code} alt="hero" className="rounded-xl w-full object-cover" />
                </div>
                <div className="w-full lg:w-4/6  lg:mt-0">
                    <h1 className="text-xl lg:text-3xl text-blue-950 font-bold align-middle lg:ml-40 mt-5">
                        Onze aanpak voor op maat gemaakte software
                    </h1>
                    <div className=" mt-8 lg:mt-12 space-y-8 lg:ml-32">
                        <Step
                            title="1. Kennismaking en requirementsanalyse"
                            description="We beginnen met een kennismakingsgesprek om uw wensen en eisen te bespreken. We analyseren uw bedrijfsprocessen en identificeren de belangrijkste vereisten voor uw software."
                            icon={<FaSearch className={classnameBoxes} />}
                            bgFrom="#8F97DD"
                            bgTo="#519AC3"
                            iconRight
                        />
                        <Step
                            title="2. Ontwerpen"
                            description="Na de requirementsanalyse beginnen we met het ontwerpen van de software. We maken een functioneel en technisch ontwerp dat voldoet aan uw eisen en wensen."
                            icon={<PiBlueprintFill className={classnameBoxes} />}
                            bgFrom="#444872"
                            bgTo="#000A66"
                            iconRight
                        />
                        <Step
                            title="3. Realisatie"
                            description="Na het ontwerpen beginnen we met de realisatie van de software. We ontwikkelen de software volgens het ontwerp."
                            icon={<FaCode className={classnameBoxes} />}
                            bgFrom="#8F97DD"
                            bgTo="#519AC3"
                            iconRight
                        />
                        <Step
                            title="4. Testen"
                            description="Na de realisatie testen we de software grondig om ervoor te zorgen dat het voldoet aan uw eisen en wensen."
                            icon={<FaBug className={classnameBoxes} />}
                            bgFrom="#2B4A99"
                            bgTo="#477BFF"
                            iconRight
                        />
                        <Step
                            title="5. Implementatie en oplevering"
                            description="Na het testen implementeren we de software in uw bedrijfsprocessen en leveren we de software op."
                            icon={<IoRocketSharp className={classnameBoxes} />}
                            bgFrom="#444872"
                            bgTo="#000A66"
                            iconRight
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row w-full lg:mt-24 mt-6">
                <div className="w-full items-center mb-12 lg:mb-0">
                    <h1 className="lg:text-3xl text-lg flex text-blue-950 font-bold align-middle lg:ml-32">
                        Onze aanpak voor Data Science en Machine Learning
                    </h1>
                    <div className="lg:mt-12 mt-5 space-y-8">
                        <Step
                            title="1. Kennismaking en business analyse"
                            description="We beginnen met een kennismakingsgesprek om uw bedrijf en uw data te leren kennen. We analyseren uw bedrijfsprocessen en identificeren de belangrijkste bedrijfsuitdagingen."
                            icon={<FaSearch className={classnameBoxes} />}
                            bgFrom="#80b4d3"
                            bgTo="#85ABC1"
                        />
                        <Step
                            title="2. Doelstellingen"
                            description="Na de business analyse bepalen we de doelstellingen van het project en definiëren we de belangrijkste KPI's."
                            icon={<TbTargetArrow className={classnameBoxes} />}
                            bgFrom="#477BFF"
                            bgTo="#2B4A99"
                        />
                        <Step
                            title="3. Data verzameling en voorbereiding"
                            description="Na het bepalen van de doelstellingen beginnen we met het verzamelen en voorbereiden van de data voor analyse."
                            icon={<FaDatabase className={classnameBoxes} />}
                            bgFrom="#000A66"
                            bgTo="#444872"
                        />
                        <Step
                            title="4. Explorative Data Analyse"
                            description="Na de data voorbereiding beginnen we met de exploratieve data analyse om inzichten te verkrijgen in de data."
                            icon={<BsGraphUp className={classnameBoxes} />}
                            bgFrom="#80b4d3"
                            bgTo="#85ABC1"
                        />
                        <Step
                            title="5. Model ontwikkeling en evaluatie"
                            description="Na de exploratieve data analyse beginnen we met de ontwikkeling van het model en evalueren we het model."
                            icon={<LuBrainCircuit className={classnameBoxes} />}
                            bgFrom="#477BFF"
                            bgTo="#2B4A99"
                        />
                        <Step
                            title="6. Resultaten presenteren"
                            description="Na de model evaluatie presenteren we de resultaten en bespreken we de bevindingen. We geven aanbevelingen voor verdere acties."
                            icon={<HiOutlinePresentationChartBar className={classnameBoxes} />}
                            bgFrom="#000A66"
                            bgTo="#444872"
                        />
                        <Step
                            title="7. Implementatie en oplevering"
                            description="Na het presenteren van de resultaten implementeren we het model in uw bedrijfsprocessen en leveren we het model op. En zorgen we een Implementatie met bijvoorbeeld een dashboard."
                            icon={<IoRocketSharp className={classnameBoxes} />}
                            bgFrom="#80b4d3"
                            bgTo="#85ABC1"
                        />
                    </div>
                </div>
                <div className="hidden lg:block   w-full lg:w-2/6 space-y-8 lg:space-y-36 items-center mt-8 lg:mt-0">
                    <img src={datascience} alt="hero" className="rounded-2xl hover:scale-105 transition-all duration-300 w-full object-cover shadow-2xl" />
                    <img src={ml} alt="hero" className="rounded-xl w-full object-cover" />
                    <img src={mlana} alt="hero" className="rounded-xl w-full object-cover" />
                </div>
            </div>
        </section>
    );
};

const Step = ({ title, description, icon, bgFrom, bgTo, iconRight }) => {
    console.log(bgFrom, bgTo)
    return (
        <div className="lg:p-0 py-0 p-1 flex flex-row items-center">
            {!iconRight && (
                <div
                    style={{ background: `linear-gradient(90deg, ${bgTo}, ${bgFrom})` }}
                    className={`hover:scale-125 transition-all duration-700 group rounded-xl shadow-2xl bg-gradient-to-r from-[${bgTo}] to-[${bgFrom}] p-2 lg:p-6 mr-2 lg:mr-8`}>
                    <div className='group-hover:rotate-180 transition-all duration-700  '>
                        
                        {icon }
                    </div>
                </div>
            )}
            <div
                style={{ background: `linear-gradient(90deg, ${bgFrom}, ${bgTo})` }}
                className={` hover:scale-95 lg:hover:scale-105 transition-all duration-500 lg:w-3/4 w-full text-left  to-[${bgTo}] lg:p-6 p-3 rounded-lg shadow-2xl`}>
                <h1 className="lg:text-xl text-base mb-2 text-white font-bold">{title}</h1>
                <p className="text-white text-sm">{description}</p>
            </div>
            {iconRight && (
                <div
                    style={{ background: `linear-gradient(90deg, ${bgTo}, ${bgFrom})` }}
                    className={`hover:scale-125 transition-all duration-700 group rounded-xl shadow-2xl bg-gradient-to-r from-[${bgTo}] to-[${bgFrom}] p-2 lg:p-6 lg:ml-8 ml-2`}>
                    <div className='group-hover:rotate-180 transition-all duration-700 '>
                        {icon}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Werkwijze;
