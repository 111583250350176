import ml from '../assets/images/pexels-googledeepmind-17483874.jpg';
import ds from '../assets/images/ds.jpg';
import desktop from '../assets/images/desktop.jpg';
import mobile from '../assets/images/mobile.jpg';
import web from '../assets/images/web.jpg';
import React, { useState, useEffect } from 'react';
export default function Expertises() {

    const [circles, setCircles] = useState([]);

    useEffect(() => {
        const generateCircles = () => {
            const circlesArray = [];
            // ... De cirkels genereren zoals eerder
            const fixedCircles = [

                { size: 42, left: -10, top: -24, backgroundColor: 'linear-gradient(145deg, #000A66, #00008B, #0000AA)' },

            ];

            setCircles([...circlesArray, ...fixedCircles]);
        };

        generateCircles();
    }, []);

    const Box = ({ title, text, initialWidth, finalWidth, image, link }) => {
        const [width, setWidth] = useState(initialWidth);

        useEffect(() => {
            // Trigger the width expansion after the component mounts
            const timer = setTimeout(() => {
                setWidth(finalWidth);
            }, 100); // Delay to ensure animation plays smoothly

            // Cleanup timer if the component unmounts
            return () => clearTimeout(timer);
        }, [finalWidth]);

        return (
            <a
                href={link}
                className={`${width} w-full self-end p-12 py-18 bg-black bg-opacity-40 border-opacity-70 border-b-black border-b-2 shadow-2xl hover:bg-opacity-20 h-34 lg:hover:scale-105 hover:scale-95  transition-transform duration-300 rounded-lg mt-5`}
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundBlendMode: 'overlay',
                    width: width,

                    transition: 'width 1.5s ease-out, transform 0.3s ease-out' // Adjusted duration to 3 seconds
                }}
            >
                <h1 className=" font-bold text-white lg:text-4xl text-3xl w-full text-center lg:text-left">{title}</h1>
            </a>
        );
    }


    const Expertises = () => {
        return (
            <section className="z-10 font-raleway mt-10 flex justify-end flex-col mb-10 w-full">
                <div className="z-10 w-full flex lg:flex-row flex-col">
                    {/* for the text */}
                    <div className="lg:w-6/12 w-full lg:p-0 p-6">
                        <h1 className=" lg:ml-20 text-3xl font-bold lg:text-white text-blue-950 drop-shadow-2xl ">Mijn expertices</h1>
                        <p className=" lg:ml-20 lg:text-white text-blue-950  text-base mt-5 lg:w-4/6 w-full">
                        Als gepassioneerde professional in softwareontwikkeling en data science heb ik veel expertise. Hieronder vindt u een overzicht van mijn vaardigheden. Dankzij mijn ervaring in deze gebieden kan ik uw projecten verbeteren en uw bedrijf succesvoller maken.                        </p>   
                                 </div>
                    {/* for machine learning,ai and data science */}
                    <div className="lg:w-11/12 flex justify-end flex-col p-4 lg:p-0 mr-16 w-full ">
                        {/* for machine learning,ai */}
                        <Box image={ml} title="Machine Learning & AI"
                            initialWidth="lg:w-1/12"
                            finalWidth="lg:w-11/12"
                            link="/expertises/ai"
                            text="Geef uw bedrijf een boost naar ongekende hoogten door mijn expertise in machine learning en AI te benutten. Ontdek de tarieven voor deze innovatieve diensten en bereid u voor op een revolutie in uw bedrijfsstrategie." />
                        {/* for data science */}
                        <Box
                            image={ds}
                            title="Data Science"
                            initialWidth="lg:w-1/12"
                            finalWidth="lg:w-full"
                            link="/expertises/ds"
                            text="Transformeer uw data in waardevolle inzichten met mijn expertise in data science. Ontdek hoe mijn diensten uw bedrijfsstrategie kunnen vernieuwen en uw organisatie naar nieuwe hoogten kunnen brengen."
                        />

                    </div>

                </div>
                <div className=" z-10 lg:mr-16 px-4 lg:px-0 justify-end flex flex-col">
                    {/* for desktop */}
                    <Box
                        image={desktop}
                        title="Desktop Applicaties"
                        initialWidth="lg:w-1/12"
                        finalWidth="lg:w-9/12"
                        link="/expertises/desktop"
                        text="Ontdek hoe mijn deskundigheid in desktopapplicaties uw projecten kan verbeteren. Met mijn ervaring kan ik uw ideeën omzetten in goed presterende, gebruiksvriendelijke desktoptoepassingen die voldoen aan uw zakelijke behoeften."
                    />

                    {/* for mobile apps */}
                    <Box
                        image={mobile}
                        title="Mobiele Apps"
                        initialWidth="lg:w-1/12"
                        finalWidth="lg:w-10/12"
                        link="/expertises/mobile"
                        text="Door mijn diepgaande ervaring in mobiele app-ontwikkeling kan ik uw projecten een geheel nieuwe dimensie geven en uw bedrijf naar ongekende successen leiden. Samen kunnen we innovatieve en gebruiksvriendelijke mobiele apps creëren die niet alleen aan uw zakelijke doelen voldoen, maar ook de gebruikers betoveren en uw merk versterken."
                    />
                    {/* for websites and webapps */}
                    <Box
                        image={web}
                        title="Websites en Webapps"
                        initialWidth="lg:w-1/12"
                        finalWidth="lg:w-11/12"
                        link="/expertises/web"
                        text="Met mijn expertise in website- en webapplicatie-ontwikkeling kan ik uw projecten naar nieuwe hoogten tillen. Laat mij u helpen bij het creëren van krachtige online platformen die uw bedrijf onderscheiden en uw klanten aanspreken, met oplossingen die naadloos aansluiten op uw zakelijke visie."
                    />




                </div>
                {/* Circles */}
                {circles.map((circle, index) => (
                    <div
                        key={index}
                        className="absolute z-0 pulsate circle opicity-50"
                        style={{
                            width: `${circle.size}vw`,
                            height: `${circle.size}vw`,
                            left: `${circle.left}%`,
                            top: `${circle.top}%`,
                            background: circle.backgroundColor,
                        }}
                    />
                ))}

            </section>
        );
    };

    return Expertises();
}