import React, { useState, useMemo } from 'react';
import { FaUserFriends, FaBrain, FaChartLine, FaDesktop, FaMobile } from 'react-icons/fa';
import { MdWeb } from 'react-icons/md';

function Dropdown({ selectedCategory, resetCategory, onSelectCategory }) {
    const [isOpen, setIsOpen] = useState(false);

    const categories = useMemo(() => [
        { name: 'Algemeen', icon: <FaUserFriends /> },
        { name: 'Machine Learning & AI', icon: <FaBrain /> },
        { name: 'Data Science', icon: <FaChartLine /> },
        { name: 'Desktop Development', icon: <FaDesktop /> },
        { name: 'Mobiele apps', icon: <FaMobile /> },
        { name: 'Websites en Webapps', icon: <MdWeb /> }
    ], []);

    const selectedIcon = useMemo(() => {
        if (resetCategory) {
            return <FaUserFriends />;
        }
        const selectedCategoryObj = categories.find(category => category.name === selectedCategory);
        return selectedCategoryObj ? selectedCategoryObj.icon : <FaUserFriends />; // Default icon if no category matches
    }, [selectedCategory, resetCategory, categories]);

    const handleDropdownClick = () => {
        setIsOpen(!isOpen);
    };

    const handleCategoryClick = (category) => {
        onSelectCategory(category.name);
        setIsOpen(false);
    };

    return (
        <div className="w-full lg:w-56 relative inline-block text-left">
            <button
                onClick={handleDropdownClick}
                className="w-full inline-flex items-center lg:w-56 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
            >
                <span className="mr-3">{selectedIcon}</span>
                {selectedCategory}
            </button>
            {isOpen && (
                <>
                    <div
                        className="fixed inset-0 z-10"
                        onClick={() => setIsOpen(false)}
                    ></div>
                    <div className="origin-top-left absolute left-0 w-56 -mt-1 rounded-b-md shadow-lg bg-white border-b border-x border-gray-300 z-20">
                        <div className="py-1">
                            {categories.map((category, index) => (
                                category.name !== selectedCategory && (
                                    <div
                                        key={index}
                                        onClick={() => handleCategoryClick(category)}
                                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                    >
                                        <span className="mr-3">{category.icon}</span>
                                        {category.name}
                                    </div>
                                )
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Dropdown;
