import React from 'react';

const TechCard = ({ title, Icon, small = false }) => {
    const fromColors = [
        'from-[#D9D9D9]', // Licht grijs
        'from-[#B2FEFA]', // Licht cyaan
        'from-[#B2F7EF]', // Licht blauw
        'from-[#FFE5D9]', // Licht perzik
        'from-[#FFEBE5]', // Licht roze
        'from-[#E7E9EB]', // Licht zilver
        'from-[#FFD9D9]', // Licht rood
        'from-[#FFD9F7]', // Licht paars


    ];
    
    const viaColors = [
        'via-[#76C2ED]', // Licht blauw
        'via-[#0ED2F7]', // Licht cyaan
        'via-[#0EF7BF]', // Licht groen
        'via-[#0EE7F7]', // Licht blauw
        
        ];
    
    const toColors = [
        'to-[#1DAEFF]', // Licht blauw
        'to-[#28C7FB]', // Licht cyaan
        'to-[#0E89E3]', // Donker blauw

        'to-[#2EC4B6]', // Donker groen
    ];
    
    
    function getRandomElement(array) {
        const randomIndex = Math.floor(Math.random() * array.length);
        return array[randomIndex];
    }
    
    function getRandomGradient() {
        const from = getRandomElement(fromColors);
        const via = getRandomElement(viaColors);
        const to = getRandomElement(toColors);
        return `${from} ${via} ${to}`;
    }
    
    // Voorbeeld van gebruik:
    const randomGradient = getRandomGradient();

    // Bepaal random richting (boven-onder of links-rechts)
    const directions = ['bg-gradient-to-b', 'bg-gradient-to-r', 'bg-gradient-to-t', 'bg-gradient-to-l'];
    const randomDirection = directions[Math.floor(Math.random() * directions.length)];
    
    return (
        <div className={`shadow-lg group hover:scale-110 group-hover:opacity-100 transition-all duration-300 relative flex flex-col items-center ${small ? 'w-28 h-28 px-2 py-4 rounded-xl ' : 'w-52 h-52 p-4 rounded-3xl'}`}>
            {/* Dynamisch de achtergrondkleur toepassen */}
            <div className={`group-hover:opacity-100 transition-all duration-300 group-hover:border-none z-0 ${randomGradient} ${randomDirection} absolute inset-0 opacity-95  ${small ? 'rounded-xl' : 'rounded-3xl'}`}></div>
            <Icon className={`group-hover:rotate-12 transition-all duration-300 text-blue-900 z-10 opacity-90 ${small ? 'w-14 h-14' : 'w-32 h-32'}`} />
            <h1 className={`z-10 font-bold opacity-80 text-blue-900 ${small ? 'text-sm mt-2' : 'text-2xl mt-4'} text-center`}>{title}</h1>
        </div>
    );
};

export default TechCard;
