// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDbwEepk8flYxsEKzokthYMbeP-5OWr61c",
  authDomain: "vinci-code-solutions.firebaseapp.com",
  projectId: "vinci-code-solutions",
  storageBucket: "vinci-code-solutions.appspot.com",
  messagingSenderId: "838709011905",
  appId: "1:838709011905:web:df933138eea77537197b40",
  measurementId: "G-87EJ2D473K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
