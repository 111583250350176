import React from 'react';
import { useEffect, useState } from 'react';

import laptopcoffee from '../assets/images/laptopcoffee.png';
import analytics from '../assets/images/analytics.png';
import '../App.css';
import BusinessPhoto from '../assets/images/businessphoto.jpg';
import graph from '../assets/images/graphimage.png';
import plant from '../assets/images/plantwater.png';
import globe from '../assets/images/glibe.png';
import peopleplan from '../assets/images/peopleplan.png';

const bgFrom = '#444872';
const bgTo = '#000A66';

const About = () => {
    const [circles, setCircles] = useState([]);

    useEffect(() => {
        const generateCircles = () => {
            const circlesArray = [];
            const fixedCircles = [
                { size: 50, left: -10, top: 13, backgroundColor: 'linear-gradient(145deg, #D4B3FE, #B394E6, #9E84D4)' },
                { size: 45, left: 65, top: 41, backgroundColor: 'linear-gradient(145deg, #4BAFFF, #3A91CC, #2A72AA)' },
                { size: 50, left: -10, top: 65, backgroundColor: 'linear-gradient(145deg, #B3F0FE, #8AD1F1, #B3F0FE)' },
            ];

            setCircles([...circlesArray, ...fixedCircles]);
        };

        generateCircles();
    }, []);

    return (
        <section className="relative overflow-hidden text-center flex flex-col font-raleway p-2 lg:p-20">

            <div className="z-10 pl-2 md:pl-20 flex flex-row w-full items-center">
                <div
                    style={{ background: `linear-gradient(90deg, ${bgFrom}, ${bgTo})` }}
                    className="ml-0 md:ml-12 mr-0 md:mr-20 transition-all duration-500 w-full text-left pl-2 md:pl-12 pr-2 md:pr-6 lg:py-6  py-2 rounded-lg shadow-2xl">
                    <h1 className="text-xs md:text-4xl mb-2 text-white font-bold">
                        Ik ben Ewaldo. Laat uw ideeën werkelijkheid worden met mijn expertise in <span className="text-cyan-400">Softwareontwikkeling</span> en <span className="text-cyan-500">Data science</span>
                    </h1>
                </div>

                <div className="md:block hidden mr-0 md:mr-36 w-full md:w-1/2 mt-4 md:mt-0">
                    <img
                        style={{ borderWidth: 40 }}
                        src={BusinessPhoto}
                        alt="hero"
                        className="border-blue-300 border-8 rounded-full transition-all duration-300 shadow-3xl"
                    />
                </div>
                <div className="block md:hidden mr-0 ml-2 w-1/2 mt-4">
                    <img
                        style={{ borderWidth: 10 }}
                        src={BusinessPhoto}
                        alt="hero"
                        className="border-blue-300 border-8 rounded-full transition-all duration-300 shadow-3xl"
                    />
                </div>
            </div>
            <div className="z-10 w-full flex flex-row mt-0 md:mt-12">
                <div className="flex flex-col space-y-11 mt-36 md:mt-0 w-full md:w-1/3 mr-0 md:mr-56">
                    <img src={analytics} alt="analytics" className="w-full relative h-2/5 object-contain" />
                    <img src={laptopcoffee} alt="laptopcoffee" className="w-full relative h-1/3 object-contain" />
                </div>

                <div className="flex flex-col w-full md:w-5/12 items-center mt-24 lg:mt-20 lg:ml-8">
                    <h1 className="text-xl lg:text-4xl text-blue-950-100 font-bold">Oprichting</h1>
                    <p className="w-full text-blue-950 md:text-3xl mt-4 lg:mt-12">
                        In 2024 heb ik Vinci Code Solutions opgericht tijdens mijn HBO-ICT studie. Met mijn bedrijf wil ik mijn vaardigheden als data scientist en softwareontwikkelaar inzetten om starters en kleinere bedrijven te helpen groeien, SaaS-oplossingen te ontwikkelen, en slimme beslissingen te nemen op basis van data. Daarnaast wil ik mezelf verder ontwikkelen op het gebied van ondernemerschap en ICT.
                    </p>
                </div>
            </div>
            <div className="z-10 w-full flex flex-row justify-end md:mt-0 mt-0 md:p-0 p-8">
                <div className="flex flex-col w-2/3 md:w-5/12 items-center  lg:ml-8">
                    <h1 className="text-xl lg:text-4xl text-blue-950 font-bold">Missie</h1>
                    <p className="text-blue-950 md:text-3xl mt-4 lg:mt-12">
                        Bij Vinci Code Solutions is het onze missie om start-ups en kleine bedrijven te ondersteunen bij hun groei en innovatie door middel van hoogwaardige softwareontwikkeling en data science oplossingen. We streven ernaar om onze klanten te helpen bij het realiseren van hun zakelijke doelen door hen te voorzien van op maat gemaakte technologieën die hun bedrijfsprocessen verbeteren en hen in staat stellen weloverwogen beslissingen te nemen op basis van geavanceerde data-analyse.
                    </p>
                </div>

                <div className="flex flex-col justify-evenly ml-6 md:space-y-24 w-1/3 md:mt-0 p-2  md:w-1/3 md:ml-56">
                    <img src={graph} alt="graph" className="md:w-1/2 w-full self-end relative object-contain" />
                    <img src={plant} alt="plant" className="md:w-1/2 w-full self-end relative object-contain" />
                </div>
            </div>
            <div className="z-10 mb-12 md:mb-24 w-full flex flex-row mt-12">
                <div className="flex flex-col space-y-2 w-7/12 md:w-1/3 mr-0 md:mr-56">
                    <img src={globe} alt="globe" className="w-full relative h-2/5 object-contain lg:-ml-4 " />
                    <img src={peopleplan} alt="globe" className="w-full relative h-1/3 lg:-ml-20 object-contain" />
                </div>

                <div className="flex flex-col w-full md:w-5/12 items-center mt-0 lg:mt-20 lg:ml-8">
                    <h1 className="text-xl lg:text-4xl text-blue-950 font-bold">Visie</h1>
                    <p className="w-full text-blue-950 md:text-3xl mt-4 lg:mt-12">
                        Onze visie is om een toonaangevende partner te zijn voor kleine bedrijven en start-ups, door continu in te spelen op de nieuwste technologische ontwikkelingen en best practices in softwareontwikkeling en data science. Wij geloven in een toekomst waarin elke onderneming, ongeacht de omvang, toegang heeft tot de tools en kennis die nodig zijn om concurrerend te blijven in een steeds veranderende markt. Door innovatieve en op maat gemaakte oplossingen te bieden, willen we bijdragen aan de groei en het succes van onze klanten, terwijl we zelf blijven groeien als een vooraanstaand bedrijf in de ICT-sector.
                    </p>
                </div>
            </div>
            {/* <div className="absolute rotate-90 hidden lg:block" style={{
                width: 0,
                height: 0,
                borderLeft: '2.5vw solid transparent',
                borderRight: '2.5vw solid transparent',
                borderBottom: '5vw solid #000A66',
                position: 'absolute',
                right: '37%',
                top: '7.8%',
                transform: 'rotate(125deg)'
            }}></div> */}
            {circles.map((circle, index) => (
                <div
                    key={index}
                    className="absolute z-0 pulsate circle transition-all duration-500 md:block hidden"
                    style={{
                        width: `${circle.size}vw`,
                        height: `${circle.size}vw`,
                        left: `${circle.left}%`,
                        top: `${circle.top}%`,
                        background: circle.backgroundColor,
                    }}
                />
            ))}
            {circles.map((circle, index) => (
                <div
                    key={index}
                    className="absolute z-0 pulsate circle transition-all duration-500 md:hidden block"
                    style={{
                        width: `${circle.size*1.4}vw`,
                        height: `${circle.size*1.4}vw`,
                        left: `${circle.left}%`,
                        top: `${circle.top + 5}%`,
                        background: circle.backgroundColor,
                    }}
                />
            ))}
        </section>
    );
};

// const Step = ({ title, description, icon, bgFrom, bgTo, iconRight }) => {
//     return (
//         <div className="lg:p-0 py-0 p-6 flex flex-col md:flex-row items-center">
//             {!iconRight && (
//                 <div
//                     style={{ background: `linear-gradient(90deg, ${bgTo}, ${bgFrom})` }}
//                     className="hover:scale-125 transition-all duration-700 group rounded-xl shadow-2xl bg-gradient-to-r from-[${bgTo}] to-[${bgFrom}] p-4 lg:p-6 mr-0 md:mr-8">
//                     <div className="group-hover:rotate-180 transition-all duration-700">
//                         {icon}
//                     </div>
//                 </div>
//             )}
//             <div
//                 style={{ background: `linear-gradient(90deg, ${bgFrom}, ${bgTo})` }}
//                 className="hover:scale-105 transition-all duration-500 lg:w-3/4 w-full text-left p-6 rounded-lg shadow-2xl">
//                 <h1 className="text-xl mb-2 text-white font-bold">{title}</h1>
//                 <p className="text-white">{description}</p>
//             </div>
//             {iconRight && (
//                 <div
//                     style={{ background: `linear-gradient(90deg, ${bgTo}, ${bgFrom})` }}
//                     className="hover:scale-125 transition-all duration-700 group rounded-xl shadow-2xl bg-gradient-to-r from-[${bgTo}] to-[${bgFrom}] p-4 lg:p-6 ml-0 md:ml-8">
//                     <div className="group-hover:rotate-180 transition-all duration-700">
//                         {icon}
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

export default About;
