// Doel: JSON-bestand met gegevens voor de pagina's van de website
// Importeer de afbeeldingen
import imageAI from './assets/images/pexels-googledeepmind-17483874.jpg';
import imageDS from './assets/images/ds.jpg';
import imageMobile from './assets/images/mobile.jpg';
import imageWeb from './assets/images/web.jpg';
import imageDesktop from './assets/images/desktop.jpg';
import { SiCsharp, SiExpo, SiJavascript, SiJquery, SiMongodb, SiMysql, SiNumpy, SiPowerbi, SiTailwindcss } from "react-icons/si";
import { FaBootstrap, FaCss3, FaDisease, FaGithub, FaHtml5, FaJava, FaLaravel, FaNodeJs, FaPhp, FaReact, FaWpforms } from "react-icons/fa";
import { RiTailwindCssFill } from "react-icons/ri";
import { RiSupabaseLine } from "react-icons/ri";
import { FaPython } from "react-icons/fa";
import { SiTensorflow } from "react-icons/si";
import { SiScikitlearn } from "react-icons/si";
import { SiPandas } from "react-icons/si";
import { SiPytorch } from "react-icons/si";
import { SiJupyter } from "react-icons/si";
import { MdScatterPlot } from "react-icons/md";
// Laad de JSON-bestand en vervang de afbeeldingspaden door de geïmporteerde afbeeldingen
const pagePropsData = {
  "mobile": {
    "image": imageMobile,
    "title": "Mobiele apps",
    "uitleg": "Mobiele apps zijn softwareprogramma's voor smartphones en tablets die bedrijven helpen hun klantbetrokkenheid te vergroten door directe toegang, gebruiksgemak en pushmeldingen. Ze bieden 24/7 beschikbaarheid en offline functionaliteit, waardoor klanten altijd toegang hebben tot diensten en producten. Door waardevolle klantdata te verzamelen, kunnen bedrijven hun marketingstrategieën optimaliseren en gepersonaliseerde ervaringen bieden. Apps verbeteren merkentrouw via loyaliteitsprogramma's en exclusieve aanbiedingen, terwijl ze ook bedrijfsprocessen automatiseren en operationele kosten verlagen. Ze bieden een concurrentievoordeel door innovatie en een betere gebruikservaring, vergroten het bereik door marktuitbreiding en sociale media integratie, en verhogen de omzet via e-commerce, in-app aankopen en advertenties. Hierdoor kunnen bedrijven efficiënter opereren, nieuwe markten bereiken en groeien.\n\n Hieronder ziet u een overzicht van verschillende technieken die wij gebruiken bij het ontwikkelen van mobiele apps.\n\n Wilt u dat wij u helpen met het ontwikkelen van een mobiele app? Neem dan nu contact op!",
    "techs": [
      { "title": "React Native", "Icon": FaReact },
      { "title": "Expo", "Icon": SiExpo },
      { "title": "TailwindCSS", "Icon": RiTailwindCssFill },
      { "title": "Supabase", "Icon": RiSupabaseLine },
      { "title": "GitHub", "Icon": FaGithub}
    ],
    "category": "Mobiele apps",
    "message": "Beste Ewaldo, Ik zou graag met je in contact willen komen over mijn mobiele app-project.",
  },
  "ai": {
    "image": imageAI,
    "title": "Machine Learning",
    "uitleg": "Machine learning en kunstmatige intelligentie (AI) zijn geavanceerde technologieën die bedrijven helpen hun klantbetrokkenheid te vergroten door gegevensgestuurde inzichten en automatisering. Ze bieden 24/7 mogelijkheden voor dataverzameling en -analyse, waardoor bedrijven altijd toegang hebben tot waardevolle informatie over klantgedrag en -voorkeuren. Door deze gegevens te gebruiken, kunnen bedrijven hun marketingstrategieën optimaliseren en gepersonaliseerde ervaringen bieden. AI en machine learning verbeteren merkentrouw door gerichte communicatie en klantgerichte aanbevelingen, terwijl ze ook bedrijfsprocessen automatiseren en operationele kosten verlagen. Ze bieden een concurrentievoordeel door innovatie en betere besluitvorming, vergroten het bereik door nauwkeurige marktanalyses en sociale media integratie, en verhogen de omzet door gerichte campagnes en efficiënte klantinteracties. Hierdoor kunnen bedrijven efficiënter opereren, nieuwe markten bereiken en groeien.\n\n Hieronder ziet u een overzicht van verschillende technieken die wij gebruiken bij het implementeren van machine learning en AI.\n\n Wilt u dat wij u helpen met het implementeren van machine learning en AI? Neem dan nu contact op!"
    ,
    "techs": [
      { "title": "Python", "Icon": FaPython },
      { "title": "Tensorflow", "Icon": SiTensorflow },
      { "title": "Scikit-learn", "Icon": SiScikitlearn },
      { "title": "Pandas", "Icon": SiPandas },
      { "title": "PyTorch", "Icon": SiPytorch },
      { "title": "Jupyter", "Icon": SiJupyter },
      { "title": "GitHub", "Icon": FaGithub },
    ],
    "category": "Machine Learning & AI",
    "message": "Beste Ewaldo, Ik zou graag met je in contact willen komen over mijn machine learning project.",
  },
  "ds": {
    "image": imageDS,
    "title": "Data Science",
    "uitleg": "Data science is een interdisciplinair vakgebied dat zich bezighoudt met het verzamelen, analyseren en interpreteren van gegevens om waardevolle inzichten te verkrijgen en besluitvorming te ondersteunen. Het omvat een breed scala aan technieken en methoden, waaronder statistische analyse, machine learning, data mining en visualisatie. Data science wordt gebruikt in verschillende sectoren, zoals gezondheidszorg, financiën, marketing en wetenschappelijk onderzoek, om trends te identificeren, patronen te voorspellen en risico's te beoordelen. Door gegevens te analyseren en te interpreteren, kunnen bedrijven hun marketingstrategieën optimaliseren, klantgedrag voorspellen en operationele processen verbeteren. Data science biedt een concurrentievoordeel door gegevensgestuurde besluitvorming en innovatie, vergroot het bereik door nauwkeurige analyses en sociale media integratie, en verhoogt de omzet door gerichte campagnes en efficiënte klantinteracties. Hierdoor kunnen bedrijven efficiënter opereren, nieuwe markten bereiken en groeien.\n\n Hieronder ziet u een overzicht van verschillende technieken die wij gebruiken bij het analyseren van data.\n\n Wilt u dat wij u helpen met het analyseren van data? Neem dan nu contact op!",
    "techs": [
      { "title": "Python", "Icon": FaPython },
      { "title": "PowerBI", "Icon": SiPowerbi },
      { "title": "Pandas", "Icon": SiPandas },
      { "title": "Numpy", "Icon": SiNumpy },
      { "title": "Scikit-learn", "Icon": SiScikitlearn },
      { "title": "Matplotlib", "Icon": MdScatterPlot },
      { "title": "Seaborn", "Icon": FaDisease },
      { "title": "Jupyter", "Icon": SiJupyter },
    ],
    "category": "Data Science",
    "message": "Beste Ewaldo, Ik zou graag met je in contact willen komen over mijn data science project.",
  },
  "desktop": {
    "image": imageDesktop,
    "title": "Desktop Applicaties",
    "uitleg": "Desktop applicaties zijn softwareprogramma's die op een desktopcomputer worden geïnstalleerd en lokaal worden uitgevoerd. Ze bieden krachtige prestaties, geavanceerde functionaliteiten en een naadloze gebruikerservaring. Desktop applicaties zijn ideaal voor complexe taken, zoals gegevensverwerking, grafische vormgeving en videobewerking, die veel rekenkracht en opslagruimte vereisen. Ze bieden offline toegang tot gegevens en bestanden, waardoor gebruikers altijd toegang hebben tot hun werk, zelfs zonder internetverbinding. Door geavanceerde beveiligingsfuncties en privacy-instellingen kunnen desktop applicaties gevoelige informatie beschermen en bedrijfsgegevens veilig houden. Ze bieden een concurrentievoordeel door innovatie en een betere gebruikservaring, vergroten het bereik door marktuitbreiding en sociale media integratie, en verhogen de omzet via e-commerce, in-app aankopen en advertenties. Hierdoor kunnen bedrijven efficiënter opereren, nieuwe markten bereiken en groeien.\n\n Hieronder ziet u een overzicht van verschillende technieken die wij gebruiken bij het ontwikkelen van desktop applicaties.\n\n Wilt u dat wij u helpen met het ontwikkelen van een desktop applicatie? Neem dan nu contact op!",
    "techs": [
      { "title": "C#", "Icon": SiCsharp },
      { "title": "WPF", "Icon": FaWpforms },
      { "title": "Java", "Icon": FaJava },
      { "title": "MySQL", "Icon": SiMysql },
      { "title": "Python", "Icon": FaPython },
      { "title": "Github", "Icon": FaGithub },
    ],
    "category": "Desktop Development",
    "message": "Beste Ewaldo, Ik zou graag met je in contact willen komen over mijn desktop applicatie project.",
  },
  "ecommerce": {
    "image": "path/to/ecommerce.jpg",
    "title": "Ecommerce Webapp",
    "hourly": {
      "price": 59.5,
      "undertitle": "per uur",
      "positives": [
        "Pas de scope van het project gemakkelijk aan",
        "Betaal alleen voor gebruikte uren",
        "Profiteer van betaalbaarheid met 59.5 per uur"
      ]
    },
    "fixed_prices": [
      {
        "title": "Klein Project",
        "price": 5000,
        "undertitle": "eenmalig",
        "positives": [
          "Basic development services",
          "Limited support"
        ]
      },
      {
        "title": "Gemiddeld Project",
        "price": 13400,
        "undertitle": "eenmalig",
        "positives": [
          "Standard development services",
          "Standard support"
        ]
      },
      {
        "title": "Groot Project",
        "price": 25100,
        "undertitle": "eenmalig",
        "positives": [
          "Comprehensive development services",
          "Extended support"
        ]
      }
    ]
  },
  "web": {
    "image": imageWeb,
    "title": "Webapp",
    "uitleg": "Websites en webapps zijn online platformen die bedrijven helpen hun klantbetrokkenheid te vergroten door directe toegang, gebruiksgemak en sociale media integratie. Ze bieden 24/7 beschikbaarheid en offline functionaliteit, waardoor klanten altijd toegang hebben tot diensten en producten. Door waardevolle klantdata te verzamelen, kunnen bedrijven hun marketingstrategieën optimaliseren en gepersonaliseerde ervaringen bieden. Websites en webapps verbeteren merkentrouw via loyaliteitsprogramma's en exclusieve aanbiedingen, terwijl ze ook bedrijfsprocessen automatiseren en operationele kosten verlagen. Ze bieden een concurrentievoordeel door innovatie en een betere gebruikservaring, vergroten het bereik door marktuitbreiding en sociale media integratie, en verhogen de omzet via e-commerce, in-app aankopen en advertenties. Hierdoor kunnen bedrijven efficiënter opereren, nieuwe markten bereiken en groeien.\n\n Hieronder ziet u een overzicht van verschillende technieken die wij gebruiken bij het ontwikkelen van websites en webapps.\n\n Wilt u dat wij u helpen met het ontwikkelen van een website of webapplicatie? Neem dan nu contact op!",
    "techs": [
      { "title": "React", "Icon": FaReact },
      { "title": "Node.js", "Icon": FaNodeJs },
      { "title": "MongoDB", "Icon": SiMongodb },
      { "title": "PHP", "Icon": FaPhp },
      { "title": "MySQL", "Icon": SiMysql },
      { "title": "Laravel", "Icon": FaLaravel },
      { "title": "HTML 5", "Icon": FaHtml5 },
      { "title": "CSS", "Icon": FaCss3 },
      { "title": "JavaScript", "Icon": SiJavascript },
      { "title": "JQuery", "Icon": SiJquery},
      { "title": "Bootstrap", "Icon": FaBootstrap },
      { "title": "Tailwind CSS", "Icon": SiTailwindcss },
      { "title": "GitHub", "Icon": FaGithub },

    ],
    "category": "Websites en Webapps",
    "message": "Beste Ewaldo, Ik zou graag met je in contact willen komen over mijn website/webapp project.",
  },
  "website_coding": {
    "image": "path/to/website_coding.jpg",
    "title": "Website Coding",
    "hourly": {
      "price": 45,
      "undertitle": "per uur",
      "positives": [
        "Pas de scope van het project gemakkelijk aan",
        "Betaal alleen voor gebruikte uren",
        "Profiteer van betaalbaarheid met 45 per uur"
      ]
    },
    "fixed_prices": [
      {
        "title": "Klein Project",
        "price": 2500,
        "undertitle": "eenmalig",
        "positives": [
          "Basic coding services",
          "Limited support"
        ]
      },
      {
        "title": "Gemiddeld Project",
        "price": 4700,
        "undertitle": "eenmalig",
        "positives": [
          "Standard coding services",
          "Standard support"
        ]
      },
      {
        "title": "Groot Project",
        "price": 12700,
        "undertitle": "eenmalig",
        "positives": [
          "Comprehensive coding services",
          "Extended support"
        ]
      }
    ]
  },
  "shopify": {
    "image": "path/to/shopify.jpg",
    "title": "Shopify",
    "hourly": {
      "price": 39,
      "undertitle": "per uur",
      "positives": [
        "Pas de scope van het project gemakkelijk aan",
        "Betaal alleen voor gebruikte uren",
        "Profiteer van betaalbaarheid met 39 per uur"
      ]
    },
    "fixed_prices": [
      {
        "title": "Klein Project",
        "price": 1400,
        "undertitle": "eenmalig",
        "positives": [
          "Basic development services",
          "Limited support"
        ]
      },
      {
        "title": "Gemiddeld Project",
        "price": 4100,
        "undertitle": "eenmalig",
        "positives": [
          "Standard development services",
          "Standard support"
        ]
      },
      {
        "title": "Groot Project",
        "price": 11000,
        "undertitle": "eenmalig",
        "positives": [
          "Comprehensive development services",
          "Extended support"
        ]
      }
    ]
  },
  "wordpress": {
    "image": "path/to/wordpress.jpg",
    "title": "Website WordPress",
    "hourly": {
      "price": 30,
      "undertitle": "per uur",
      "positives": [
        "Pas de scope van het project gemakkelijk aan",
        "Betaal alleen voor gebruikte uren",
        "Profiteer van betaalbaarheid met 30 per uur"
      ]
    },
    "fixed_prices": [
      {
        "title": "Klein Project",
        "price": 1100,
        "undertitle": "eenmalig",
        "positives": [
          "Basic development services",
          "Limited support"
        ]
      },
      {
        "title": "Gemiddeld Project",
        "price": 3200,
        "undertitle": "eenmalig",
        "positives": [
          "Standard development services",
          "Standard support"
        ]
      },
      {
        "title": "Groot Project",
        "price": 8500,
        "undertitle": "eenmalig",
        "positives": [
          "Comprehensive development services",
          "Extended support"
        ]
      }
    ]
  }
};


export default pagePropsData;
