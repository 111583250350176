import React from 'react';
import { FaFacebook, FaLinkedin, FaPhone, FaEnvelope } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import Logo from '../components/Logo';
import LongWhiteLine from '../components/LongWhiteLine';

const Footer = () => {
    return (
        <footer className="z-10 relative bg-gradient-to-r from-[#0071C2] via-[#000A66] to-[#000A66] text-white py-4 rounded-t-xl text-left font-raleway">
            <div className="container mx-auto px-6 flex flex-col lg:flex-row justify-between items-start lg:items-center">
                <a href="/home" className="flex items-center mb-4 lg:mb-0">
                    <Logo color='#ffffff' className='w-1/2 h-20 lg:w-full lg:h-52' />
                    <div className="flex flex-col lg:w-full lg:-ml-8">
                        <h1 className="text-white ml-12  text-3xl lg:text-8xl" style={{ fontFamily: 'STIX Two Text' }}>VCS</h1>
                        <p className="text-white text-sm lg:text-lg lg:ml-12" style={{ fontFamily: 'Raleway' }}>Vinci Code Solutions</p>
                    </div>
                </a>
                <div className="flex flex-col lg:flex-row lg:items-center lg:space-x-12">
                    <div className="flex flex-col text-white text-lg space-y-4 lg:pl-24">
                        <a href="https://www.vincicodesolutions.nl" className="flex items-center">
                            <Logo color='#ffffff' width={30} height={30} className="mr-2" />
                            <span>Vinci Code Solutions</span>
                        </a>
                        <a href="mailto:vincicodesolutions@gmail.com"className="flex items-center">
                            <FaEnvelope className="mr-5" />
                            <span>vincicodesolutions@gmail.com</span>
                        </a>
                        <a href="tel:+310624375748"
                         className="flex items-center">
                            <FaPhone className="mr-5" />
                            <span>+31 0624375748</span>
                        </a>
                        <div className="flex items-center">
                            <span className="mr-5">KVK:</span>
                            <span>94521441</span>
                        </div>
                    </div>
                    <nav className="flex flex-col text-lg space-y-2 lg:space-y-1 lg:space-x-4 mt-8">
                        <a href="/home" className="lg:ml-4 hover:text-blue-300">Home</a>
                        <a href="/werkwijze" className="hover:text-blue-300">Werkwijze</a>
                        <a href="/works" className="hover:text-blue-500">Projecten</a>
                        <a href="/certificates" className="hover:text-blue-500">Certificaten</a>
                        <a href="/expertises" className="hover:text-blue-500">Expertises</a>
                        <a href="/prijzen" className="hover:text-blue-500">Prijzen</a>
                        <a href="/about" className="hover:text-blue-500">Over ons</a>
                        <a href="/contact" className="hover:text-blue-500">Contact</a>
                    </nav>
                    <div className="flex space-x-4 lg:space-x-12 mt-4 lg:mt-0">
                        <a href="https://www.linkedin.com/company/104550339/" target="_blank" rel="noopener noreferrer">
                            <FaLinkedin className="text-white hover:text-blue-700 w-8 h-8 lg:w-12 lg:h-12" />
                        </a>
                        <a href="https://x.com/vincicodesolu" target="_blank" rel="noopener noreferrer">
                            <FaXTwitter className="text-white hover:text-gray-900 w-8 h-8 lg:w-12 lg:h-12" />
                        </a>
                        <a href="https://www.facebook.com/people/Vinci-Code-Solutions/61563018725629/" target="_blank" rel="noopener noreferrer">
                            <FaFacebook className="text-white hover:text-blue-500 w-8 h-8 lg:w-12 lg:h-12" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="text-center text-white mt-12 lg:mt-24">
                <div className="px-4 lg:px-12">
                    <LongWhiteLine />
                </div>
                Vinci Code Solutions © 2024 - All rights reserved
            </div>
        </footer>
    );
}

export default Footer;
