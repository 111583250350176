import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import WebFont from 'webfontloader';
import Header from './components/Header';
import MainSection from './components/MainSection';
import Footer from './components/Footer';
import Works from './pages/Projects';
import Certificates from './pages/Certificates';
import Contact from './pages/Contact';
import HireMe from './pages/HireMe';
import SkillsPage from './pages/SkillsPage';
import Pricing from './pages/PricingPage';
import Werkwijze from './pages/Werkwijze';
import pagePropsData from './pageProps';
import Expertises from './pages/Expertises';
import SingleExpertise from './pages/SingleExpertise';
import About from './pages/About';
import Buildings from './assets/images/buildings.jpg';
import ProjectDetails from './pages/ProjectDetails'; // Nieuwe import
import projectProps from './projectProps'; // Importeer de projectdata

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['STIX Two Text', 'Raleway']
      }
    });
  }, []);

  const location = useLocation();

  let backgroundStyle =
    location.pathname === "/home" || location.pathname === "/" || location.pathname === "/certificates" || location.pathname === "/contact"
      ? "linear-gradient(to bottom, #fbfaff, #fbfaff, #eae5fc, #8cccdb)"
      : "linear-gradient(to bottom, #B3F0FE, #4BAFFF, #444872)";
  
  if(location.pathname === '/about'){
    backgroundStyle = "linear-gradient(to bottom, #D8DEFF,#76CADC, #1442B9)";
  }

  let backgroundImage = null;

  if (location.pathname.startsWith("/prijzen")) {
    backgroundImage = Buildings;
  }

  if (location.pathname.startsWith("/expertises/")) {
    const pricingType = location.pathname.split("/")[2];
    if(pagePropsData[pricingType]?.image != null){
      backgroundImage = pagePropsData[pricingType]?.image;
    }
  }
  if (location.pathname.startsWith("/project/")) {
    backgroundStyle = "linear-gradient(to bottom, #fbfaff,#76CADC, #4BAFFF)";
  }

  function getProjectByName(name) {
    return projectProps.projects.find(element => {
      return element.title.toLowerCase().replace(/ /g, '-') === name;
    });
  };
  
  const ProjectDetailsWrapper = () => {
    const { projectName } = useParams();
    const project = getProjectByName(projectName);
    return <ProjectDetails project={project} />;
  };

  
  return (
      <div
        className="flex flex-col min-h-screen"
        style={{
          background: backgroundStyle,
          backgroundAttachment: "local",
          ...(backgroundImage && {
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundBlendMode: 'overlay',
            backgroundRepeat: 'no-repeat',
          }),
        }}
      >
        <Header />
        <main className="flex-grow h-full">
          <Routes>
            <Route path="/home" element={<MainSection />} />
            <Route path="/werkwijze" element={<Werkwijze />} />
            <Route path="/prijzen" element={<Pricing />} />
            <Route path="/works" element={<Works />} />
            <Route path="/certificates" element={<Certificates />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/hire-me" element={<HireMe />} />
            <Route path="/expertises" element={<Expertises />} />
            <Route path="/expertises/:type" element={<SingleExpertise {...pagePropsData[window.location.pathname.split("/")[2]]} />} />
            <Route path="/pricing/:type" element={<SkillsPage {...pagePropsData[window.location.pathname.split("/")[2]]} />} />
            <Route path="/project/:projectName" element={<ProjectDetailsWrapper />} /> {/* Nieuwe route */}
            <Route path="/" element={<MainSection />} />
          </Routes>
        </main>
        <div className="lg:px-10 px-2">
          <Footer />
        </div>
      </div>
  );
}

export default App;
