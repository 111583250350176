import React from 'react';
import Project from '../components/Project';  // Zorg ervoor dat het pad correct is
import projectProps from '../projectProps';
import { useState, useEffect } from 'react';
export default function Projects() {
    const [circles, setCircles] = useState([]);

    useEffect(() => {
        const generateCircles = () => {
            const fixedCircles = [
                { size: 30, left: 0, top: 0, backgroundColor: 'linear-gradient(145deg, #B3F0FE, #8AD1F1, #5CA9F3)' },
                { size: 8, left: 52, top: 10, backgroundColor: 'linear-gradient(145deg, #000A66, #00008B, #0000AA)' },
                { size: 8, left: 42, top: 2, backgroundColor: 'linear-gradient(145deg, #D4B3FE, #B394E6, #9E84D4)' },
                { size: 8.5, left: 64, top: 9, backgroundColor: 'linear-gradient(145deg, #4BAFFF, #3A91CC, #2A72AA)' },
                { size: 30, left: 0, top: 22, backgroundColor: 'linear-gradient(145deg, #B3F0FE, #8AD1F1, #5CA9F3)' },
                { size: 7, left: 42, top: 27, backgroundColor: 'linear-gradient(145deg, #B3F0FE, #8AD1F1, #5CA9F3)' },
                { size: 8, left: 48, top: 32, backgroundColor: 'linear-gradient(145deg, #B3F0FE, #8AD1F1, #5CA9F3)' },
                { size: 30, left: 60, top: 37, backgroundColor: 'linear-gradient(145deg, #000A66, #00008B, #0000AA)' },
                { size: 8, left: 52, top: 47, backgroundColor: 'linear-gradient(145deg, #000A66, #00008B, #0000AA)' },
                { size: 5, left: 44, top: 42, backgroundColor: 'linear-gradient(145deg, #000A66, #00008B, #0000AA)' },
                { size: 30, left: 0, top: 53, backgroundColor: 'linear-gradient(145deg, #D4B3FE, #B394E6, #9E84D4)' },
                { size: 8, left: 42, top: 55, backgroundColor: 'linear-gradient(145deg, #D4B3FE, #B394E6, #9E84D4)' },
                { size: 5, left: 55, top: 60, backgroundColor: 'linear-gradient(145deg, #D4B3FE, #B394E6, #9E84D4)' },
                { size: 8.5, left: 64, top: 68, backgroundColor: 'linear-gradient(145deg, #4BAFFF, #3A91CC, #2A72AA)' },
                { size: 30, left: 60, top: 66, backgroundColor: 'linear-gradient(145deg, #4BAFFF, #3A91CC, #2A72AA)' },
                { size: 4, left: 59, top: 67, backgroundColor: 'linear-gradient(145deg, #4BAFFF, #3A91CC, #2A72AA)' },
                { size: 30, left: 0, top: 80, backgroundColor: 'linear-gradient(145deg, #000A66, #00008B, #0000AA)' },
                { size: 7, left: 30, top: 93, backgroundColor: 'linear-gradient(145deg, #000A66, #00008B, #0000AA)' },
                { size: 8, left: 43, top: 89, backgroundColor: 'linear-gradient(145deg, #000A66, #00008B, #0000AA)' },
                { size: 4, left: 32, top: 81, backgroundColor: 'linear-gradient(145deg, #000A66, #00008B, #0000AA)' },
            ];

            setCircles([...fixedCircles]);
        };

        generateCircles();
    }, []);

    return (
        <section className=" relative container font-raleway mx-auto overflow-hidden ">
            <div className='py-8 px-4 '>
                <div className="relative z-10">
                    <h1 className="z-10 lg:text-3xl text-xl text-blue-950 lg:ml-28 font-bold ">Mijn ervaringen</h1>
                    <h2 className="z-10 lg:text-lg lg:w-1/2 text-blue-800 lg:ml-28 font-semibold mb-8 text-left mt-2 ">Hieronder vind je een overzicht van de projecten waaraan ik heb gewerkt.</h2>
                </div>
                <div className="z-0 space-y-8 lg:px-28 px-0">
                    {/* Loop over de projectProps en maak voor elk project een Project component aan */}
                    {projectProps.projects.map((project, index) => (
                        <Project
                            key={index}
                            year={project.year}
                            title={project.title}
                            imageSrc={project.imageSrc}
                            description={project.description}
                            logo={project.logo}
                            techs={project.techs}
                            links={project.links}
                        />
                    ))}
                    {/* Voorbeeld van een Project component
                <Project 
                    year="2024"
                    title="RePhase"
                    imageSrc={rephaseImage}
                    description="RePhase is een innovatief project waaraan ik heb gewerkt, gericht op het ontwikkelen van een veelzijdige app die gebruikers ondersteunt bij het stellen van doelen, het volgen en creëren van workouts, en het beheren van voedingsplannen. Het is een alles-in-één lifestyle-app die streeft naar het verbeteren van de gezondheid en fitheid van gebruikers.

Tijdens dit project heb ik een React Native-app gebouwd die gebruikers in staat stelt om eenvoudig workouts te volgen en toe te voegen. De app biedt een intuïtieve interface en een scala aan functies die het voor gebruikers gemakkelijk maken om hun fitnessactiviteiten te organiseren en te delen. Door de integratie van voedingsplannen helpt de app gebruikers niet alleen fitter te worden, maar ook om een gebalanceerd en gezond dieet te handhaven.

Mijn werk aan RePhase demonstreert mijn uitgebreide vaardigheden in mobiele app-ontwikkeling, waaronder mijn vermogen om complexe functionaliteiten te implementeren en een naadloze gebruikerservaring te creëren. "
                />
                <Project 
                    year="2023"
                    title="WinChat"
                    imageSrc={winchatImage}
                    altText="WinChat project"
                    description="WinChat is een project ontwikkeld voor Windesheim, gericht op het verbeteren van communicatie binnen teams..."
                /> */}
                </div>
            </div>
            {circles.map((circle, index) => (
                <div
                    key={index}
                    className="absolute z-0 pulsate circle"
                    style={{
                        width: `${circle.size}vw`,
                        height: `${circle.size}vw`,
                        left: `${circle.left}%`,
                        top: `${circle.top}%`,
                        background: circle.backgroundColor,
                    }}
                />
            ))}

        </section>
    );
}
