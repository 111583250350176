import React from 'react';
import { FaCheck, FaEuroSign } from 'react-icons/fa';
import { FaPaperPlane } from 'react-icons/fa6';
const PricingCard = ({ title, price, undertitle, positives, variant, background, category, message }) => {

  const handleClick = (data) => {
    localStorage.setItem('contactFormData', JSON.stringify(data));
  };

  return (
    <div
      style={{
        ...(background ? { backgroundImage: `url(${background})`, backgroundSize: 'cover' } : {})
      }}
      className={`relative z-10 font-sans transition-all duration-500 bg-white transform lg:hover:scale-105 hover:scale-95 cursor-pointer rounded-xl drop-shadow-xl flex flex-col items-center justify-between p-6 w-full sm:w-60 md:w-96 lg:w-80 xl:w-96`}
    >
      {/* Zwarte overlay */}
      {background && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 rounded-xl z-1 pointer-events-none"></div>
      )}

      <div className="relative z-2 text-center">
        <h1 className="text-lg text-gray-400 mb-4">{title}</h1>
        <div className="flex flex-row justify-center items-center">
          <FaEuroSign className="text-3xl text-black mt-2" />
          {variant === 2 ? (
            <p className="text-7xl text-black font-semibold shadow-2xl drop-shadow-2xl">{price}</p>
          ) : (
            <p className="text-black text-8xl font-semibold drop-shadow-2xl">{price}</p>
          )}
        </div>
        <p className="text-gray-400">{undertitle}</p>
      </div>

      <div className="flex-grow" /> {/* This pushes the positives section to the bottom */}

      <div className="relative z-2 w-full mt-4">
        {positives.map((positive, index) => (
          <div key={index} className="flex items-center mb-2">
            <FaCheck className="text-indigo-600 mr-2" />
            <p className="drop-shadow-sm text-gray-400 font-semibold ">{positive}</p>
          </div>
        ))}
      </div>

      {/* <a
 className={`relative z-2 text-2xl md:text-3xl transition-all duration-500 shadow-xl bg-gradient-to-r from-blue-600 via-indigo-500 to-blue-600 text-white w-full py-2 px-4 mt-4 rounded-xl
        ${variant !== 2 ? 'hover:from-blue-800 hover:to-blue-700 hover:via-indigo-800' : ''}`}>

        Neem contact op
      </a> */}
      <a
        onClick={() => handleClick({ category: category, message: message })}
        href="/contact"
        className="group shadow-xl mt-3 bg-gradient-to-r from-blue-600 via-indigo-500 to-cyan-600 hover:from-blue-800 hover:to-cyan-800 hover:via-indigo-600 text-white px-6 sm:px-10 text-sm font-bold py-3 lg:py-0 rounded-lg flex transition-transform transform hover:scale-105 w-full items-center"
      >
        <FaPaperPlane size="" className=" lg:w-12 lg:h-7 lg:-ml-5 w-9 h-9 pr-2  text-blue-50 group-hover:animate-ping transition-transform" />
        <p className="text-xl w-full font-raleway ml-5">Neem contact op</p>
      </a>
    </div>
  );
};

export default PricingCard;
