import React, { useEffect, useState } from 'react';
import { FaCircleDot, FaUser } from 'react-icons/fa6';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import owl from "../assets/images/blue-owl-wide-left.jpeg";
import { FaBuilding, FaGithub, FaLinkedin, FaPhoneAlt, FaRegUser, FaWhatsapp } from 'react-icons/fa';
import { MdOutlineMarkEmailUnread } from 'react-icons/md';
import Dropdown from '../components/Dropdown';
import { firestore } from '../firebase';
import { addDoc, collection } from 'firebase/firestore';

export default function Contact() {
    const ref = collection(firestore, "contacten");
    const [showMessage, setShowMessage] = useState(false);
    const [messageColor, setMessageColor] = useState('text-green-500');
    const [phone, setPhone] = useState('');
    const [melding, setMelding] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        company: '',
        message: '',
        category: 'Algemeen',
    });

    useEffect(() => {
        const savedData = localStorage.getItem('contactFormData');
        if (savedData) {
          setFormData(JSON.parse(savedData));
        }
        localStorage.removeItem('contactFormData');
      }, []);

    const [resetCategory, setResetCategory] = useState(false);

    const handleCategorySelect = (category) => {
        setFormData({ ...formData, category });
        setResetCategory(false);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    function convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Create a new Date object representing the current date and time
            const submittedAt = new Date();
            
            let formattedDate = "";

            try {
              // Veronderstel dat de tijdzone uit de opgeslagen datum komt
              const submittedAtTwo = new Date(submittedAt); // Veronderstel dat submittedAt een Date string is met tijdzone
              const timeZone = "Europe/Amsterdam"; // Stel dat we 'Europe/Amsterdam' als standaard tijdzone gebruiken
        
              // Converteer de UTC tijd naar de gewenste tijdzone
              const zonedDate = convertTZ(submittedAtTwo, timeZone);
        
              // Formatteer de datum in de gewenste stringvorm
              const options = { weekday: 'long', day: 'numeric', month: 'long', hour: '2-digit', minute: '2-digit', hour12: false, timeZone: timeZone };
              formattedDate = zonedDate.toLocaleDateString('nl-NL', options);
            } catch (error) {
              console.error("Fout bij het parsen van de datum:", error);
              formattedDate = "onbekende datum"; // Fallback voor als de datum parsing mislukt
            }
            console.log('formattedDate:', formattedDate);
            // Add the new date object to the form data
            await addDoc(ref, { ...formData, phone, submittedAt });
    
            // Reset the form data and other states
            setFormData({
                firstName: '',
                middleName: '',
                lastName: '',
                email: '',
                company: '',
                message: '',
                category: 'Algemeen'
            });
    
            setResetCategory(true);
            setPhone(''); // Reset phone input
            console.log('Form submission success');
            setMelding('Bericht is succesvol vertuurd');
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
    
        } catch (error) {
            console.error('Form submission error:', error);
            setMelding('Bericht is NIET succesvol vertuurd, probeer het nog eens');
            setShowMessage(true);
            setMessageColor('text-red-500');
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
        }
    };    

    return (
        <section className="font-raleway z-10 overflow-hidden">
            <div className="w-full md:flex-row flex flex-col">
                <img style={{ height: '86vh' }} src={owl} alt="an owl" className='hidden lg:block' />
                <img  src={owl} alt=" an owl" className="lg:hidden block w-full "/>

                <div className="lg:w-1/2 lg:p-12 p-6">
                    <h1 className="text-2xl text-blue-950">Laten we in contact komen</h1>
                    <form onSubmit={handleSubmit}>
                        <p className="text-gray-500">
                            of stuur een mail naar <a href="mailto:vincicodesolutions@gmail.com" className="text-blue-500 hover:text-blue-700">vincicodesolutions@gmail.com</a> of bel ons op <a href="tel:+310624375748" className="text-cyan-500 hover:text-cyan-700">+31 0624375748</a>
                        </p>
                        <p className="text-sm text-gray-600">
                            Velden met een <span className="text-xl text-red-500 font-bold">*</span> zijn verplicht
                        </p>
                        <div className="lg:mt-6 mt-2 flex lg:flex-row flex-col w-full lg:items-center ">
                            <div className="relative lg:w-1/2 w-full">
                                <label htmlFor="firstName" className="text-blue-950">Voornaam<span className="text-red-500 text-2xl">*</span></label>
                                <div className="wrapper relative">
                                    <FaUser className="icon absolute h-6 w-6 text-gray-500 top-1/2 left-2 transform -translate-y-1/2" />
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        className="input w-full border border-gray-300 rounded-lg p-2 pl-10"
                                        required
                                        value={formData.firstName}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="relative lg:w-1/2 w-full lg:ml-12 ">
                                <label htmlFor="middleName" className="text-blue-950">Tussenvoegsels<span className="text-red-500 text-2xl opacity-0">*</span></label>
                                <div className="wrapper relative">
                                    <FaCircleDot className="icon absolute h-3 w-3 text-gray-500 top-1/2 left-2 transform -translate-y-1/2" />
                                    <input
                                        type="text"
                                        id="middleName"
                                        name="middleName"
                                        className="input w-full border border-gray-300 rounded-lg p-2 pl-10"
                                        value={formData.middleName}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 flex lg:flex-row flex-col w-full lg:items-center ">
                            <div className="relative lg:w-1/2 w-full">
                                <label htmlFor="lastName" className="text-blue-950">Achternaam<span className="text-red-500 text-2xl">*</span></label>
                                <div className="wrapper relative">
                                    <FaRegUser className="icon absolute h-6 w-6 text-gray-500 top-1/2 left-2 transform -translate-y-1/2" />
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        className="input w-full border border-gray-300 rounded-lg p-2 pl-10"
                                        required
                                        value={formData.lastName}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="relative lg:w-1/2 lg:ml-12 w-full">
                                <label htmlFor="email" className="text-blue-950">Email adres<span className="text-red-500 text-2xl">*</span></label>
                                <div className="wrapper relative">
                                    <MdOutlineMarkEmailUnread className="icon absolute h-6 w-6 text-gray-500 top-1/2 left-2 transform -translate-y-1/2" />
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        className="input w-full border border-gray-300 rounded-lg p-2 pl-10"
                                        required
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 flex lg:flex-row flex-col w-full lg:items-center ">
                            <div className="relative lg:w-1/2 w-full lg:-mr-0">
                                <label htmlFor="company" className="text-blue-950">Bedrijfsnaam<span className="text-red-500 text-2xl">*</span></label>
                                <div className="wrapper relative">
                                    <FaBuilding className="icon absolute h-6 w-6 text-gray-500 top-1/2 left-2 transform -translate-y-1/2" />
                                    <input
                                        type="text"
                                        id="company"
                                        name="company"
                                        className="input w-full border border-gray-300 rounded-lg p-2 pl-10"
                                        required
                                        value={formData.company}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="relative lg:w-1/2 lg:ml-12 w-full ">
                                <label htmlFor="phone" className="text-blue-950">Telefoonnummer<span className="text-red-500 text-2xl opacity-0">*</span></label>
                                <PhoneInput
                                    className='w-full border text-sm border-gray-300 rounded-lg p-2'
                                    placeholder="Voer uw telefoonnummer in"
                                    value={phone}
                                    onChange={setPhone}
                                    defaultCountry="NL"
                                />
                            </div>
                        </div>
                        <div className="mt-2 w-full">
                            <div className="relative">
                                <label htmlFor="category" className="text-blue-950">Selecteer een categorie<span className="text-red-500 text-2xl">*</span></label>
                                <br />
                                <Dropdown 
                                selectedCategory={formData.category}
                                resetCategory={resetCategory}
                                onSelectCategory={handleCategorySelect} />
                            </div>
                        </div>
                        <div className="mt-2 w-full">
                            <div className="relative">
                                <label htmlFor="message" className="text-blue-950">Uw missie voor Vinci Code Solutions:<span className="text-red-500 text-2xl">*</span></label>
                                <div className="wrapper">
                                    <textarea
                                        type="text"
                                        id="message"
                                        name="message"
                                        className="max-h-64 input w-full border border-gray-300 rounded-lg p-2"
                                        required
                                        value={formData.message}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="z-0 mt-6 lg:mt-2 flex flex-row w-full items-center ">
                            <button type="submit" className="bg-blue-600 hover:bg-blue-800 text-white rounded-lg py-2 px-4 w-full lg:w-64">Verstuur</button>
                        </div>
                        {showMessage && <p className={messageColor}>{melding}</p>}
                    </form>
                </div>
            </div>
            <div className="lg:p-12 lg:pt-0 px-6 pb-20">
                <button className=" cursor-default lg:text-base text-sm text-gray-600 font-bold text-clip p-1.5 px-2 border-2 border-gray-300 rounded-xl">
                    Neem contact met ons op
                </button>
                <h1 className="lg:text-2xl text-lg font-bold mt-6 text-blue-950">Neem contact met ons op voor een vrijblijvend gesprek over uw project. Wij helpen u graag verder.</h1>
                <div className="flex lg:flex-row flex-col space-y-6 lg:space-y-0 lg:items-center justify-between lg:mt-8 mt-4">
                    <div className='flex flex-col'>
                        <a href="mailto:vincicodesolutions@gmail.com" className="bg-blue-200 rounded-full p-2 w-min">
                            <MdOutlineMarkEmailUnread className='text-blue-600 lg:w-12 lg:h-12 h-6 w-6' />
                        </a>
                        <h1 className="lg:text-xl font-bold text-blue-950 lg:mt-4 mt-2">Email</h1>
                        <p className='text-gray-500 text-sm lg:w-44'>Bereik ons via email zodat we u verder kunnen helpen</p>
                        <a href='mailto:vincicodesolutions@gmail.com' className='lg:mt-4 mt-2 text-blue-500 hover:text-blue-700 text-sm'>vincicodesolutions@gmail.com</a>
                    </div>
                    <div className='flex flex-col '>
                        <a href='https://wa.me/message/4YCQVLN4LPQTI1' className="bg-blue-200 rounded-full p-2 w-min mt-2 ">
                            <FaWhatsapp className='text-blue-600 lg:w-12 lg:h-12 w-6 h-6' />
                        </a>
                        <h1 className="lg:text-xl font-bold text-blue-950 lg:mt-4 mt-2">WhatsApp</h1>
                        <p className='text-gray-500 text-sm  lg:w-44 lg:pb-6'>Benader ons direct via WhatsApp</p>
                        <a href='https://wa.me/message/4YCQVLN4LPQTI1' className='lg:mt-4 mt-2 text-blue-500 hover:text-blue-700 text-sm'>Stuur een bericht</a>
                    </div>

                    <div className='flex flex-col '>
                        <a href="tel:+310624375748" className="bg-blue-200 rounded-full lg:p-3 p-2 w-min">
                            <FaPhoneAlt className='text-blue-600 lg:size-9 size-6' />
                        </a>
                        <h1 className="lg:text-xl font-bold text-blue-950 lg:mt-4 mt-2">Bel ons</h1>
                        <p className='text-gray-500 lg:w-44 text-sm'>Bel ons direct, we zijn beschikbaar in het weekend en na 16:00</p>
                        <a href='tel:+310624375748' className='lg:mt-4 mt-2 text-blue-500 hover:text-blue-700 text-sm'>(+31) 0624375748</a>
                    </div>
                    <div className='flex flex-col '>
                        <a  href="https://www.linkedin.com/company/104550339/" className="bg-blue-200 rounded-full p-2 w-min">
                            <FaLinkedin className='text-blue-600 lg:size-12 size-8' />
                        </a>
                        <h1 className="lg:text-xl font-bold text-blue-950 lg:mt-4 mt-2 ">LinkedIn</h1>
                        <p className='text-gray-500 text-sm lg:w-44 lg:pb-4'>Maak een connectie met ons op LinkedIn</p>
                        <a href='https://www.linkedin.com/company/104550339/' className='lg:mt-4 mt-2 text-blue-500 hover:text-blue-700 text-sm'>Vinci Code Solutions op LinkedIn</a>
                    </div>
                    <div className='flex flex-col'>
                        <a href="https://github.com/EwaldoNieuwenhuis" className="bg-blue-200 rounded-full p-2 w-min">
                            <FaGithub className='text-blue-600 lg:size-12 size-8' />
                        </a>
                        <h1 className="lg:text-xl font-bold text-blue-950 lg:mt-4 mt-2">Github</h1>
                        <p className='text-gray-500 lg:w-44 text-sm lg:pb-8'>Bezoek mijn GitHub pagina</p>
                        <a href='https://github.com/EwaldoNieuwenhuis' className='lg:mt-4 mt-2 text-blue-500 hover:text-blue-700 text-sm'>Ewaldo Nieuwenhuis op GitHub</a>
                    </div>
                </div>
            </div>
        </section>
    );
};
